import React, { Fragment, useEffect } from 'react';
import { keycloak, ROLE_TYPE_DISPLAY } from '@/config';
import { BaseUser } from 'swuif';
import Countly from 'countly-sdk-web'; // <- npm install countly-sdk-web
import { realmHeartBeat } from '@/routes/AuthLogin';

const appVersion = process.env.REACT_APP_VERSION!.toUpperCase(); // bad .env?
const key = keycloak.realm;
const a = {
  path: {
    [key.GSE]: '/gse',
    [key.MALL]: '/mall',
    [key.RECOM]: '/recommendation',
    [key.VDA]: '/vda',
    ADMIN_CONSOLE: '/admin',
    SIDE_BAR: '/sidebar',
    UPCOMING_FEATURE: '/upcoming',
    SUPPORT: '/support',
    DID_SETTING: '/didsetting',
    TIPS: '/plugin',
    ASSETS: '/assets',
    EXPLORE: '/explore',
  },
  realmGroup: {
    [key.GSE]: key.GSE,
    [key.MALL]: key.MALL,
    [key.RECOM]: key.RECOM,
    ADMIN_CONSOLE: key.MALL,
    FEATURED: key.MALL,
    LIFESTYLE: key.MALL,
    [key.VDA]: key.VDA,
    SUPPORT: key.SUPPORT,
    DID_SETTING: key.MALL,
  },
  userID: '',
  screenResolution: '',
  userRoles: {},
  setUser: (user?: BaseUser, height?: any, width?: any) => {
    a.userID = '';
    a.userRoles = {};
    a.screenResolution = `${height}x${width}`;
    if (user) {
      a.userID = user.userId.split('@')[0];
      console.log('userroles', user.roles);
      if (user.roles['ROUTINE']) {
        a.userRoles['ROUTINE'] = user.roles['ROUTINE'];
      }
      for (const realmID of Object.keys(a.path)) {
        const val = ROLE_TYPE_DISPLAY[user.roles[realmID]];
        if (val) a.userRoles[realmID] = val;
      }
    }
  },
  init: () => {
    const ts = new Date().getTime();
    Countly.init({
      app_key: process.env.REACT_APP_COUNT_KEY,
      url: process.env.REACT_APP_COUNT_URL,
      app_version: appVersion,
    });
    keycloak.debugRealmAlert(31, ['CLYinit', ts - new Date().getTime()]);
    // track sessions automatically
    Countly.track_sessions();
    // track pageviews automatically
    Countly.track_pageview();

    Countly.track_links();
    // Countly.track_forms();
    Countly.track_errors();
  },
  segmentation: (realmID: string, name: string) => {
    let segmen = {
      app: realmID,
      roleName: a.userRoles[realmID] || '',
      userID: a.userID || '',
      type: 'button',
      name: name, // see track_pageview
      version: appVersion,
      // time: String(new Date()), // see add_cly_events in countly.js
    };

    return segmen;
  },
  segmentationBaru: (
    realmID: string,
    activityDesc: any,
    contentId: any,
    contentPage: string,
    contentState: any,
  ) => {
    let segmen = {
      app: realmID,
      roleName: a.userRoles[realmID] || '',
      userID: a.userID || '',
      type: 'button',
      version: appVersion,
      content_page: contentPage,
      // time: String(new Date()), // see add_cly_events in countly.js
    };
    console.log('userroles', a.userRoles);
    segmen['app'] =
      segmen['app'] === 'TIPS'
        ? 'PLUGIN'
        : segmen['app'] === 'ROUTINE'
        ? 'RECOMMENDATION'
        : segmen['app'] === 'RECOM'
        ? 'RECOMMENDATION'
        : segmen['app'];
    if (activityDesc) {
      segmen['activity_desc'] = activityDesc;
      if (contentId) {
        segmen['content_id'] = contentId;
      }
      if (contentState) {
        segmen['content_state'] = contentState;
      }
    } else {
      segmen['screenResolution'] = a.screenResolution;
    }
    console.log('segmen', segmen);
    return segmen;
  },
  lastPage: '',
  logPage: (pathname: string) => {
    const modified = a.lastPage !== pathname;
    if (modified) {
      a.lastPage = pathname;
      Countly.track_pageview();
      keycloak.debugRealmAlert(31, ['CLYpage', pathname]);
    }
    return modified;
  },
  logTodo: async (name: string, groupID = 'NEW') => {
    keycloak.debugRealmAlert(131, ['CLYevent todo', groupID, name]);
    return true;
  },
  logButton: async (groupID: string, name: string) => {
    const realmID = a.realmGroup[groupID];
    if (!realmID) {
      return a.logTodo(name, groupID);
    }
    const event = {
      key: `${groupID} button`,
      segmentation: a.segmentation(realmID, name),
    };
    Countly.add_event(event);
    keycloak.debugRealmAlert(31, ['CLYevent', realmID, name, event]);
    realmHeartBeat(realmID);
    return true;
  },
  logButtonBaru: async (
    groupID: string,
    activityDesc: any,
    contentId: any,
    contentPage: string,
    contentState: any,
  ) => {
    const realmID = a.realmGroup[groupID];
    const act = activityDesc ? activityDesc : 'view';
    const event = {
      key: `${groupID} button`,
      segmentation: a.segmentationBaru(
        groupID,
        activityDesc,
        contentId,
        contentPage,
        contentState,
      ),
    };

    if (!realmID) {
      Countly.add_event(event);
      return a.logTodo(act, groupID);
    }
    Countly.add_event(event);

    keycloak.debugRealmAlert(31, ['CLYevent', realmID, act, event]);
    return true;
  },
  key: {
    herobanner: 'hero_banner',
    howtouse: 'howtouse',
    notification: 'notification',
    video: 'video',
    page: 'page',
    plugin: 'plugin',
  },
  btGse: {
    otherBaru: (
      activityDesc: string,
      contentId: any,
      contentPage: any,
      contentState: string,
    ) =>
      a.logButtonBaru(
        key.GSE,
        activityDesc,
        contentId,
        contentPage,
        contentState,
      ),
    other: (name: string) => a.logButton(key.GSE, name),
    singleBulk: (name: string, mode: string) =>
      a.btGse.other(`create ${name} ${mode}`),

    preview: (name: string) => a.btGse.other(`create ${name} preview`),
    submit: (name: string) => a.btGse.other(`create ${name} submit`),
    createBaru: (
      name: string,
      contentId: string,
      contentPage: any,
      contentState: string,
    ) =>
      a.btGse.otherBaru(`create ${name}`, contentId, contentPage, contentState),
    save: (
      name: string,
      contentId: string,
      contentPage: any,
      contentState: string,
    ) =>
      a.btGse.otherBaru(`update ${name}`, contentId, contentPage, contentState),
    submitBaru: (
      name: string,
      contentId: string,
      contentPage: any,
      contentState: any,
    ) => a.btGse.otherBaru(name, contentId, contentPage, contentState),
    listData: (
      activityDesc: any,
      contentId: any,
      contentPage: string,
      contentState: any,
    ) => a.btGse.otherBaru(activityDesc, contentId, contentPage, contentState),
    importCSV: (name: string) => a.btGse.other(`create ${name} import csv`),
    downloadTemplate: (name: string) =>
      a.btGse.other(`create ${name} download template`),
    create: (name: string) => a.btGse.other(`content create ${name}`),
    state: (name: string) => a.btGse.other(`content state ${name}`),
    search: (name: string) => a.btGse.other(`Search on ${name} Column`),
    actSingle: (name: string) => a.btGse.other(`${name} Single Content`),
    action: (name: string) => a.btGse.other(`${name} content`),
  },
  btMall: {
    otherBaru: (
      activityDesc: string,
      contentId: any,
      contentPage: any,
      contentState: any,
    ) =>
      a.logButtonBaru(
        key.MALL,
        activityDesc,
        contentId,
        contentPage,
        contentState,
      ),
    submitBaru: (
      name: string,
      contentId: any,
      contentPage: any,
      contentState: any,
    ) => a.btMall.otherBaru(name, contentId, contentPage, contentState),
    listData: (
      activityDesc: any,
      contentId: any,
      contentPage: string,
      contentState: any,
    ) => a.btMall.otherBaru(activityDesc, contentId, contentPage, contentState),
    createBaru: (
      name: string,
      contentId: any,
      contentPage: any,
      contentState: any,
    ) =>
      a.btMall.otherBaru(
        `create ${name}`,
        contentId,
        contentPage,
        contentState,
      ),
    update: (
      name: string,
      contentId: any,
      contentPage: any,
      contentState: any,
    ) =>
      a.btMall.otherBaru(
        `update ${name}`,
        contentId,
        contentPage,
        contentState,
      ),
    clickCreateType: (type: string) => a.btMall.other(`create new ${type}`),
    searchFrom: (type: string) => a.btMall.other(`check from ${type}`),
    downloadTemplate: () => a.btMall.other('download template'),
    importCsv: () => a.btMall.other('import data'),
    submitForm: (type: string) => a.btMall.other(`submit form ${type}`),
    switchState: (type: string) => a.btMall.other(`switch to ${type} tab`),
    switchCountry: (type: string) =>
      a.btMall.other(`switch country to ${type}`),
    transition: (type: string, total: number, action: string) =>
      a.btMall.other(`${type} ${action} ${total} merchandise`),
    search: (name: string) => a.btMall.other(`Search on ${name} Column`),
    sort: (name: string) => a.btMall.other(`Sort on ${name} Column`),
    other: (name: string) => a.logButton(key.MALL, name),
  },
  btFtMd: {
    other: (name: string) => a.logButton('FEATURED', name),
    switchState: (type: string) => a.btFtMd.other(`switch to ${type} tab`),
    switchCountry: (type: string) =>
      a.btFtMd.other(`switch country to ${type}`),
  },
  btLfSt: {
    other: (name: string) => a.logButton('LIFESTYLE', name),
    sort: (colName: string) => a.btLfSt.other(`sort ${colName}`),
    search: (colName: string) => a.btLfSt.other(`search ${colName}`),
    switchState: (type: string) => a.btLfSt.other(`switch to ${type} tab`),
    switchCountry: (type: string) =>
      a.btLfSt.other(`switch country to ${type}`),
    transition: (type: string, total: number, action: string) =>
      a.btLfSt.other(`${type} ${action} ${total} theme`),
  },
  btRecom: {
    other: (name: string) => a.logButton(key.RECOM, name),
    otherBaru: (
      activityDesc: string,
      contentId: string,
      contentPage: any,
      contentState: string,
    ) =>
      a.logButtonBaru(
        key.RECOM,
        activityDesc,
        contentId,
        contentPage,
        contentState,
      ),
    submitBaru: (
      name: string,
      contentId: string,
      contentPage: any,
      contentState: any,
    ) => a.btRecom.otherBaru(name, contentId, contentPage, contentState),

    listData: (
      activityDesc: any,
      contentId: any,
      contentPage: string,
      contentState: any,
    ) =>
      a.btRecom.otherBaru(activityDesc, contentId, contentPage, contentState),
    createBaru: (
      name: string,
      contentId: any,
      contentPage: any,
      contentState: string,
    ) =>
      a.btRecom.otherBaru(
        `create ${name}`,
        contentId,
        contentPage,
        contentState,
      ),
    update: (
      name: string,
      contentId: any,
      contentPage: any,
      contentState: string,
    ) =>
      a.btRecom.otherBaru(
        `update ${name}`,
        contentId,
        contentPage,
        contentState,
      ),
    actContent: (action: string) => a.btRecom.other(`${action} content`),
    saveContent: () => a.btRecom.other('Save button'),
    createNew: () => a.btRecom.other('create new'),
    importData: () => a.btRecom.other('import data'),
  },
  btRecomRoutine: {
    other: (name: string) => a.logButton(key.ROUTINE, name),
    otherBaru: (
      activityDesc: string,
      contentId: string,
      contentPage: any,
      contentState: string,
    ) =>
      a.logButtonBaru(
        key.ROUTINE,
        activityDesc,
        contentId,
        contentPage,
        contentState,
      ),
    submitBaru: (
      name: string,
      contentId: string,
      contentPage: any,
      contentState: any,
    ) => a.btRecomRoutine.otherBaru(name, contentId, contentPage, contentState),

    listData: (
      activityDesc: any,
      contentId: any,
      contentPage: string,
      contentState: any,
    ) =>
      a.btRecomRoutine.otherBaru(
        activityDesc,
        contentId,
        contentPage,
        contentState,
      ),
    createBaru: (
      name: string,
      contentId: any,
      contentPage: any,
      contentState: string,
    ) =>
      a.btRecomRoutine.otherBaru(
        `create ${name}`,
        contentId,
        contentPage,
        contentState,
      ),
    update: (
      name: string,
      contentId: any,
      contentPage: any,
      contentState: string,
    ) =>
      a.btRecomRoutine.otherBaru(
        `update ${name}`,
        contentId,
        contentPage,
        contentState,
      ),
    actContent: (action: string) => a.btRecomRoutine.other(`${action} content`),
    saveContent: () => a.btRecomRoutine.other('Save button'),
    createNew: () => a.btRecomRoutine.other('create new'),
    importData: () => a.btRecomRoutine.other('import data'),
  },
  btVDA: {
    other: (name: string) => a.logButton(key.VDA, name),
    actContent: (action: string) => a.btVDA.other(`${action} content`),
    sortContent: () => a.btVDA.other(`sort content`),
    filterContent: () => a.btVDA.other(`filter content`),
    saveDeviceContent: () => a.btVDA.other(`Edit new device file`),
    createDeviceNew: () => a.btVDA.other(`create new device file`),
    deleteDevice: () => a.btVDA.other(`delete device file`),
    createResourceNew: () => a.btVDA.other(`create new resource file`),
    saveResourceContent: () => a.btVDA.other(`Edit new resource file`),
    deleteResource: () => a.btVDA.other(`delete resource file`),
  },
  btPlugin: {
    otherBaru: (
      activityDesc: string,
      contentId: string,
      contentPage: any,
      contentState: string,
    ) =>
      a.logButtonBaru(
        'TIPS',
        activityDesc,
        contentId,
        contentPage,
        contentState,
      ),
    submitBaru: (
      name: string,
      contentId: string,
      contentPage: any,
      contentState: any,
    ) => a.btPlugin.otherBaru(name, contentId, contentPage, contentState),

    createBaru: (
      name: string,
      contentId: any,
      contentPage: any,
      contentState: any,
    ) =>
      a.btPlugin.otherBaru(
        `create ${name}`,
        contentId,
        contentPage,
        contentState,
      ),
    update: (
      name: string,
      contentId: any,
      contentPage: any,
      contentState: any,
    ) =>
      a.btPlugin.otherBaru(
        `update ${name}`,
        contentId,
        contentPage,
        contentState,
      ),
    listData: (
      activityDesc: any,
      contentId: any,
      contentPage: string,
      contentState: any,
    ) =>
      a.btPlugin.otherBaru(activityDesc, contentId, contentPage, contentState),
  },
  btAssets: {
    otherBaru: (
      activityDesc: string,
      contentId: string,
      contentPage: any,
      contentState: any,
    ) =>
      a.logButtonBaru(
        key.ASSETS,
        activityDesc,
        contentId,
        contentPage,
        contentState,
      ),
    listData: (
      activityDesc: any,
      contentId: any,
      contentPage: string,
      contentState: any,
    ) =>
      a.btAssets.otherBaru(activityDesc, contentId, contentPage, contentState),
    createBaru: (
      name: string,
      contentId: any,
      contentPage: any,
      contentState: any,
    ) =>
      a.btAssets.otherBaru(
        `create ${name}`,
        contentId,
        contentPage,
        contentState,
      ),
    update: (
      name: string,
      contentId: any,
      contentPage: any,
      contentState: any,
    ) =>
      a.btAssets.otherBaru(
        `update ${name}`,
        contentId,
        contentPage,
        contentState,
      ),
    submitBaru: (
      name: string,
      contentId: string,
      contentPage: any,
      contentState: any,
    ) => a.btAssets.otherBaru(name, contentId, contentPage, contentState),
  },
  btNotice: {
    other: (name: string) => a.logButton(key.SUPPORT, name),
    actContent: (action: string) => a.btNotice.other(`${action} content`),
    sortContent: () => a.btNotice.other(`sort notification content`),
    filterContent: () => a.btNotice.other(`filter notification content`),
    saveNotificationContent: () =>
      a.btNotice.other(`Edit notification content`),
    createNotificationNew: () =>
      a.btNotice.other(`create new notification content`),
    deleteNotification: () => a.btNotice.other(`delete notification content`),
    publishNotification: () => a.btNotice.other(`publish notification content`),
    unpublishNotification: () =>
      a.btNotice.other(`unpublish notification content`),
  },
  btAdmin: (name: string) => a.logButton('ADMIN_CONSOLE', name),
};
export const activity = a;

const ActivityMonitoring = (props: any) => {
  useEffect(() => {
    // if (process.env.NODE_ENV !== 'production') {
    a.init();
    // }
  }, []);
  return <Fragment>{props.children}</Fragment>;
};

export default ActivityMonitoring;
