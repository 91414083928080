import { api_mode } from '@/config/constants_mall';

export const getGraphqlQuery = (mode: any, parameter: any = {}) => {
  console.log('##parameter', parameter);
  switch (mode) {
    case api_mode.GET_DETAIL_SUPER_CATEGORY:
      return `{
        superCategory(id: ${parameter.id}){
          id,
          name,
          localizations{
            locale,
            displayName
          },
          updatedTime,
          subCategoryIds
        }
      }`;
    case api_mode.UPSERT_SUPER_CATEGORY:
      return `mutation{
        upsertSuperCategory(superCategory: {
          name: "${parameter.name}",
          localizations: [${parameter.localizations}],
          ${parameter.id ? `id: ${parameter.id}` : ''},
          subCategoryIds: [${parameter.subCategoryIds}]
        }), {
          id,
          name,
          localizations{
            locale,
            displayName
          },
          updatedTime,
          subCategoryIds
        }
      }`;
    case api_mode.DELETE_SUPER_CATEGORY:
      return `mutation{
        deleteSuperCategory(id: ${parameter.id})
      }`;
    case api_mode.GET_ALL_SUPER_CATEGORIES:
      return `{
        allSuperCategories(locale: "${parameter.locale}"){
          totalCount,
          superCategories{
            id,
            name,
            localizations{
              locale,
              displayName
            },
            updatedTime,
            subCategoryIds
          }
        }
      }`;
    case api_mode.GET_ALL_BRANDS_WITHOUT_PAGINATION:
      return `{
        allBrands(
          sort : {
            name: ASC
          },
          pageNumber:0){
          totalCount,
          brands{
            id,
            name,
            iconUrl,
            uuid
          }
        }
      }
    `;

    case api_mode.GET_ALL_BRANDS_WITH_PARAMS:
      return `{
      allBrands(
        ${parameter.pageNumber ? `pageNumber: ${parameter.pageNumber},` : ``}
        ${parameter.pageSize ? `pageSize : ${parameter.pageSize},` : ``}
        sort : {
          name: ${parameter.sortOrder}
        },
        filter : {
          releaseStatusIn : ${parameter.filter.releaseStatusIn}
        }
      ){
        totalCount,
        brands{
          id,
              name,
              iconUrl,
              uuid,
              stComDetail{
                summaryDescription,
                logoUrl,
                homepageUrl,
                shopUrl,
                supportNotes,
                email
              }
              releaseStatus,
              updatedTime,
        }
      }
    }
  `;

    case api_mode.GET_SINGLE_BRAND:
      return `{
          brand(
            id:${parameter.id},
            name : "${parameter.name}"
          ){
              id,
              name,
              iconUrl,
              uuid,
              stComDetail{
                summaryDescription,
                logoUrl,
                homepageUrl,
                shopUrl,
                supportNotes,
                email
              }
              releaseStatus,
              updatedTime,
          }
        }
      `;

    case api_mode.CREATE_SINGLE_BRAND_DRAFT:
      return `mutation{
      createBrand(
        input:{
          brand:{
            name : "${parameter.name}",
            iconUrl : "${parameter.iconUrl}",
            stComDetail: ${parameter.stComDetail}
          }
        }
      ){
        id,
              name,
              iconUrl,
              uuid,
              stComDetail{
                summaryDescription,
                logoUrl,
                homepageUrl,
                shopUrl,
                supportNotes,
                email
              }
              releaseStatus,
              updatedTime,
      }
    }`;

    case api_mode.DELETE_SINGLE_BRAND:
      return `mutation{
      deleteBrand(
        id : ${parameter.id}
      )
    }`;

    case api_mode.UNPUBLISH_PUBLISHED_BRAND:
      return `mutation{
      unpublishBrand(
        id : ${parameter.id}
      ){
        id,
              name,
              iconUrl,
              uuid,
              stComDetail{
                summaryDescription,
                logoUrl,
                homepageUrl,
                shopUrl,
                supportNotes,
                email
              }
              releaseStatus,
              updatedTime,
      }
    }`;

    case api_mode.SUBMIT_BRAND_FOR_APPROVAL:
      return `mutation{
      submitBrandForApproval(
        id : ${parameter.id}
      ){
        id,
              name,
              iconUrl,
              uuid,
              stComDetail{
                summaryDescription,
                logoUrl,
                homepageUrl,
                shopUrl,
                supportNotes,
                email
              }
              releaseStatus,
              updatedTime,
      }
    }`;

    case api_mode.APPROVE_REQUESTED_BRAND:
      return `mutation{
    publishBrand(
      id : ${parameter.id}
    ){
      id,
              name,
              iconUrl,
              uuid,
              stComDetail{
                summaryDescription,
                logoUrl,
                homepageUrl,
                shopUrl,
                supportNotes,
                email
              }
              releaseStatus,
              updatedTime,
    }
  }`;

    case api_mode.CANCEL_REQUESTED_BRAND:
      return `mutation{
      cancelBrand(
        id : ${parameter.id}
      ){
        id,
              name,
              iconUrl,
              uuid,
              stComDetail{
                summaryDescription,
                logoUrl,
                homepageUrl,
                shopUrl,
                supportNotes,
                email
              }
              releaseStatus,
              updatedTime,
      }
    }`;

    case api_mode.SAVE_SINGLE_BRAND_DRAFT:
      return `mutation{
      updateBrand(
        input:{
          id: ${parameter.id}
          brand:{
            name : "${parameter.name}",
            uuid: ${parameter.uuid ? `"${parameter.uuid}"` : null},
            iconUrl : "${parameter.iconUrl}",
            stComDetail: ${parameter.stComDetail}
          }
        }
      ){
        id,
        name,
        iconUrl,
      }
    }`;

    case api_mode.GET_ALL_TAGS_WITHOUT_PAGINATION:
      return `{
        allTags(
          pageNumber:0){
          totalCount,
          tags{
            id,
            name
          }
        }
      }`;

    case api_mode.GET_ALL_CATEGORIES_WITHOUT_PAGINATION:
      return `{
        allCategories(
          ${
            parameter.locale
              ? `sort : {
                displayName: ASC
              },
              filter:{
            locale :"${parameter.locale}"
          },`
              : ''
          }
          pageNumber:0
          ){
          totalCount,
          categories{
            id,
            uuid,
            name,
            iconUrl,
            localizations{
              categoryId,
              locale,
              displayName
            },
            dossierCategoryNames,
            superCategoryId
          }
        }
      }`;

    case api_mode.GET_ALL_DOSSIER:
      return `{
        dossierCategories{
          items{
            name,
            resourceType,
          }
        }
      }`;

    case api_mode.SET_DOSSIER_CATEGORY_MAPPING:
      return `mutation{
        setDossierCategoryMapping(
          categoryId : ${parameter.categoryId},
          dossierCategoryNames : ["${parameter.dossierCategoryNames}"],
        ){
          categories{
            id,
            uuid,
            name,
            iconUrl,
            localizations{
              categoryId,
              locale,
              displayName
            },
            dossierCategoryNames,
            updatedTime,
            visibilityOs,
          }
        }
      }`;

    case api_mode.GET_ALL_CATEGORIES:
      return `{
          allCategories(
            pageNumber:${parameter.pageNumber},
            ${parameter.pageSize ? `pageSize: ${parameter.pageSize},` : ''}
            ${parameter.sort ? parameter.sort : ''}
            ${
              parameter.searchOption && parameter.searchBy
                ? `filter:{
                searchBy: "${parameter.searchBy}",
                searchOption: ${parameter.searchOption},
              }`
                : ``
            }
            ){
            totalCount,
            categories{
              id,
              uuid,
              name,
              iconUrl,
              localizations{
                categoryId,
                locale,
                displayName
              },
              dossierCategoryNames,
              updatedTime,
              visibilityOs,
              superCategoryId
            }
          }
        }`;

    case api_mode.DELETE_SINGLE_CATEGORY:
      return `mutation{
        deleteCategory(id : ${parameter.categoryId})
    }`;

    case api_mode.GET_SINGLE_CATEGORY:
      return `{
        category(
          id:${parameter.categoryId}
          ){
            id,
            uuid,
            name,
            iconUrl,
            localizations{
              categoryId,
              locale,
              displayName
            },
            dossierCategoryNames,
            updatedTime,
            visibilityOs,
          }
      }`;

    case api_mode.INSERT_SINGLE_CATEGORY:
      return `mutation{
        upsertCategory(
          category:{
            name: "${parameter.name}",
            ${parameter.uuid ? `uuid: "${parameter.uuid}",` : ``}
            iconUrl: "${parameter.iconUrl}",
            localizations: [${parameter.localizations}]
            visibilityOs: ${parameter.visibilityOs},
            ${
              parameter.dossierCategoryNames
                ? `dossierCategoryNames : ["${parameter.dossierCategoryNames}"],`
                : ``
            }
          }
        ){
          id,
          uuid,
          name,
          iconUrl,
          localizations{
            categoryId,
            locale,
            displayName
          },
          dossierCategoryNames,
          updatedTime,
          visibilityOs,
        }
      }`;

    case api_mode.UPDATE_SINGLE_CATEGORY:
      return `mutation{
        upsertCategory(
          category:{
            id: ${parameter.id},
            ${parameter.uuid ? `uuid: "${parameter.uuid}",` : ``}
            name: "${parameter.name}",
            ${parameter.uuid ? `uuid: "${parameter.uuid}",` : ``}
            iconUrl: "${parameter.iconUrl}",
            localizations: [${parameter.localizations}]
            visibilityOs: ${parameter.visibilityOs},
            ${
              parameter.dossierCategoryNames
                ? `dossierCategoryNames : ["${parameter.dossierCategoryNames}"],`
                : ``
            }
          }
        ){
          id,
          uuid,
          name,
          iconUrl,
          localizations{
            categoryId,
            locale,
            displayName
          },
          dossierCategoryNames,
          updatedTime,
          visibilityOs,
        }
      }`;

    case api_mode.GET_ALL_THEMES_WITHOUT_PAGINATION:
      return `{
          allServiceThemes(
            sort : {
              name: ASC
            },
            filter : {
              ${parameter.locale ? `locale : "${parameter.locale}",` : ''}
            },
            pageNumber:0,){
            totalCount,
            serviceThemes{
              id,
              name,
              serviceCode,
              backgroundImageUrl,
              localizations{
                locale,
                title,
                message,
                description,
              },
              releaseStatus,
            }
          }
        }
      `;
    case api_mode.GET_ALL_STORIES:
      return `{
        allStories(
             pageNumber:${parameter.pageNumber},
             ${parameter.pageSize ? `pageSize: ${parameter.pageSize},` : ''}
             filter:${parameter.filter},
             sort: ${parameter.sort}
         )
         {
             totalCount,pageInfo{totalPages},
             stories{
                     id,
                     name,
                     country,
                     title,
                     description,
                     backgroundImageUrl,
                     videoUrl,
                     displayEndTime,
                     displayStartTime,
                     releaseStatus,
                     categoryIds,
                     featuredPriority,
                     updatedTime
                 }
             }
     }
     `;
    case api_mode.GET_ALL_THEMES:
      return `{
          allServiceThemes(
            ${parameter.pageNumber ? `pageNumber:${parameter.pageNumber},` : ''}
            ${parameter.pageSize ? `pageSize: ${parameter.pageSize},` : ''}
            ${parameter.sort ? parameter.sort : ''}
            filter:{
              ${
                parameter.filter.locale
                  ? parameter.searchBy &&
                    parameter.filter.searchOption.serviceCode &&
                    !parameter.sort
                    ? ``
                    : `locale : "${parameter.filter.locale}",`
                  : ``
              }
              ${
                parameter.filter.releaseStatusIn === 'ALL'
                  ? ''
                  : `releaseStatusIn : [${parameter.filter.releaseStatusIn}],`
              }
              ${
                parameter.searchBy
                  ? `searchBy: "${parameter.searchBy}",
                    searchOption:{
                      serviceCode:${parameter.filter.searchOption.serviceCode},
                      title:${parameter.filter.searchOption.title}
                    }
              `
                  : ''
              }
            },){
            totalCount,
            serviceThemes{
              id,
              name,
              serviceCode,
              backgroundImageUrl,
              localizations{
                locale,
                title,
                message,
                description,
              },
              releaseStatus,
            }
          }
        }
      `;
    case api_mode.GET_ALL_THEMES_EXPORT:
      return `{
            allServiceThemes(
              ${parameter.sort ? parameter.sort : ''}
              filter:{
                ${
                  parameter.filter.locale
                    ? parameter.searchBy &&
                      parameter.filter.searchOption.serviceCode &&
                      !parameter.sort
                      ? ''
                      : `locale : "${parameter.filter.locale}",`
                    : ''
                }
                ${
                  parameter.filter.releaseStatusIn === 'ALL'
                    ? ''
                    : `releaseStatusIn : [${parameter.filter.releaseStatusIn}],`
                }
                ${
                  parameter.searchBy
                    ? `searchBy: "${parameter.searchBy}",
                      searchOption:{
                        serviceCode:${parameter.filter.searchOption.serviceCode},
                        title:${parameter.filter.searchOption.title}
                      }
                `
                    : ''
                }
              },){
              totalCount,
              serviceThemes{
                id,
                name,
                serviceCode,
                backgroundImageUrl,
                localizations{
                  locale,
                  title,
                  message,
                  description,
                },
                releaseStatus,
              }
            }
          }
        `;
    case api_mode.SUBMIT_SINGLE_LIFESTYLE:
      return `mutation{
          submitServiceThemeForApproval(
            id: ${parameter.themeId}
            ){
              serviceTheme{
                id,
                name,
                serviceCode,
                backgroundImageUrl,
                reservedModelCodes,
                localizations{
                  locale,
                  title,
                  message,
                  description,
                },
                releaseStatus,
            }
          }
        }`;
    case api_mode.CANCEL_SINGLE_LIFESTYLE:
      return `mutation{
            cancelServiceTheme(
              id: ${parameter.themeId}
              ){
                serviceTheme{
                  id,
                  name,
                  serviceCode,
                  backgroundImageUrl,
                  reservedModelCodes,
                  localizations{
                    locale,
                    title,
                    message,
                    description,
                  },
                  releaseStatus,
              }
            }
          }`;
    case api_mode.PUBLISH_SINGLE_LIFESTYLE:
      return `mutation{
        publishServiceTheme(
          id: ${parameter.themeId}
          ){
            serviceTheme{
              id,
              name,
              serviceCode,
              backgroundImageUrl,
              reservedModelCodes,
              localizations{
                locale,
                title,
                message,
                description,
              },
              releaseStatus,
          }
        }
      }`;
    case api_mode.UNPUBLISH_SINGLE_LIFESTYLE:
      return `mutation{
          unpublishServiceTheme(
            id: ${parameter.themeId}
            ){
              serviceTheme{
                id,
                name,
                serviceCode,
                backgroundImageUrl,
                reservedModelCodes,
                localizations{
                  locale,
                  title,
                  message,
                  description,
                },
                releaseStatus,
            }
          }
        }`;
    case api_mode.DELETE_SINGLE_LIFESTYLE:
      return `mutation{
      deleteServiceTheme(id : ${parameter.themeId})
    }`;
    case api_mode.GET_SINGLE_LIFESTYLE:
      return `{
        serviceTheme(
            id:${parameter.id}
          ){
          serviceTheme{
            id,
            name,
            serviceCode,
            backgroundImageUrl,
            reservedModelCodes,
            localizations{
              locale,
              title,
              message,
              description,
            },
            releaseStatus,
          },
          productCatalogListOf{
            id,
          uuid,
          modelCode,
          modelName,
          countries,
          brand{
            id, uuid, name, iconUrl, releaseStatus
          },
          categories{
            id, uuid, name, iconUrl,
            localizations{
              categoryId, locale, displayName, updatedTime
            }, visibilityOs, updatedTime
          },
          localizations{
            locale, displayName, featureDescription, shopDisplayName, shopFeatureDescription
          },
          shopItems{
            country, language, buyUrl, shopName
          },
          imageContent{
            defaultImageUrl,
            importedImages{
              country,
              imageUrl,
              shopImageUrl
            }
          },
          featuredPriorities{
            country, priority
            },
         isPurchasable,
          hubRequired,
          releaseDate,
          autoSync,
          releaseStatus,
          updatedTime,
          visibilityOs
          },
        }
      }
    `;
    case api_mode.ADD_SINGLE_LIFESTYLE:
      return `mutation{
        createServiceTheme(
          input:{
            serviceCode: "${parameter.serviceCode}"
            name: "${parameter.name}"
            backgroundImageUrl: "${parameter.backgroundImageUrl}"
            localizations: [${parameter.localizations}]
          }
        ){
          serviceTheme{
            id,
            name,
            serviceCode,
            backgroundImageUrl,
            reservedModelCodes,
            localizations{
              locale,
              title,
              message,
              description,
            },
            releaseStatus,
          },
          productCatalogListOf{
            id,
          uuid,
          modelCode,
          modelName,
          countries,
          brand{
            id, uuid, name, iconUrl, releaseStatus
          },
          featuredPriorities{
            country, priority
            },
            imageContent{
              defaultImageUrl,
              importedImages{
                country, imageUrl, shopImageUrl
              }
            }
        }
      }
    }`;

    case api_mode.UPDATE_SINGLE_LIFESTYLE:
      return `mutation{
        updateServiceTheme(
          input:{
            id:${parameter.id},
            serviceThemeInput:{
              serviceCode: "${parameter.serviceCode}"
              name: "${parameter.name}"
              backgroundImageUrl: "${parameter.backgroundImageUrl}"
              localizations: [${parameter.localizations}]
              productCatalogIds: [${parameter.productIds}]
              ${
                parameter.reservedModelCodes
                  ? `reservedModelCodes: [${parameter.reservedModelCodes}]`
                  : ''
              }
            },
          }
        ){
          serviceTheme{
            id,
            name,
            serviceCode,
            backgroundImageUrl,
            reservedModelCodes,
            localizations{
              locale,
              title,
              message,
              description,
            },
            releaseStatus,
          },
          productCatalogListOf{
            id,
          uuid,
          modelCode,
          modelName,
          countries,
          brand{
            id, uuid, name, iconUrl, releaseStatus
          },
          featuredPriorities{
            country, priority
          },
          imageContent{
            defaultImageUrl,
            importedImages{
              country, imageUrl, shopImageUrl
            }
          }
        }
      }
      }`;

    case api_mode.UPSERT_SINGLE_LIFESTYLE:
      return `mutation{
        upsertServiceTheme(
          input:{
            id:${parameter.id},
            releaseStatus: ${parameter.releaseStatus},
            serviceThemeInput:{
              serviceCode: "${parameter.serviceCode}"
              name: "${parameter.name}"
              backgroundImageUrl: "${parameter.backgroundImageUrl}"
              merchandiseIds: [${parameter.merchandiseIds}]
              localizations: [${parameter.localizations}]
              ${
                parameter.reservedModelCodes
                  ? `reservedModelCodes: [${parameter.reservedModelCodes}]`
                  : ''
              }
            },
          }
        ){
          serviceTheme{
            id,
            name,
            serviceCode,
            backgroundImageUrl,
            reservedModelCodes,
            localizations{
              locale,
              title,
              message,
              description,
            },
            releaseStatus,
          },
          productCatalogListOf{
            id,
          uuid,
          modelCode,
          modelName,
          countries,
          brand{
            id, uuid, name, iconUrl, releaseStatus
          },
          featuredPriorities{
            country, priority
            },
            imageContent{
              defaultImageUrl,
              importedImages{
                country, imageUrl, shopImageUrl
              }
            }
        }
      }`;

    case api_mode.LINK_MERCHANDISES_TO_LIFESTYLE:
      return `mutation {
        linkMerchandisesToServiceTheme(
            serviceThemeId: ${parameter.id}
            merchandiseIdsToLink: [${parameter.merchandiseIds}],
        )
        {
            serviceTheme {
            id
            releaseStatus
          }
        }
    }`;

    case api_mode.UNLINK_MERCHANDISES_FROM_LIFESTYLE:
      return `mutation {
        unlinkMerchandisesFromServiceTheme(
            serviceThemeId: ${parameter.id}
            merchandiseIdsToUnlink: [${parameter.merchandiseIds}],
        )
        {
          serviceTheme {
            id,
            releaseStatus
          }
        }

    }`;

    case api_mode.ADD_BULK_TAGS:
      return `mutation{
          createBulkTags(
            tags : [${parameter.names}]
          ){
            totalCount,
            tags{
              id,
              name
            }
          }
        }`;

    case api_mode.ADD_SINGLE_MANAGED_PRODUCT:
      return `mutation{
        upsertManagedProduct(
          managedProduct:{
            country: "${parameter.country}",
            modelCode: "${parameter.modelCode}",
            categoryIds:[${parameter.categoryIds}],
          }
        ){
          id,
          country,
          modelCode,
          categories{
            id,
            name
          }
        }
      }`;

    case api_mode.DELETE_SINGLE_MANAGED_PRODUCT:
      return `mutation{
          deleteManagedProduct(
            country: "${parameter.country}",
            modelCode: "${parameter.modelCode}",
            id:${parameter.id},
          )
        }`;

    case api_mode.GET_ALL_MANAGED_PRODUCT:
      return `{
        allManagedProducts(
          pageNumber:0,
          ${
            parameter.country !== 'All'
              ? `filter: {
            country: "${parameter.country}"
          }`
              : ''
          }
        ){
          totalCount,
          managedProducts{
            id,
            country,
            modelCode
          }
        }

      }`;

    case api_mode.SET_LINK_PRODUCT_CATALOGS_TO_SERVICE_THEME:
      return `mutation{
        setLinkProductCatalogsToServiceTheme(serviceThemeId: ${parameter.id}, productCatalogIdsToLink: [${parameter.productIds}]),{
          serviceTheme{
            id,
            name,
            serviceCode,
            backgroundImageUrl,
            localizations{
              locale, title, message, description
            },
            reservedModelCodes,
            releaseStatus,
            updatedTime
          },
          productCatalogListOf{
            id,
          uuid,
          modelCode,
          modelName,
          countries,
          brand{
            id, uuid, name, iconUrl, releaseStatus
          },
          categories{
            id, uuid, name, iconUrl,
            localizations{
              categoryId, locale, displayName, updatedTime
            }, visibilityOs, updatedTime
          },
          localizations{
            locale, displayName, featureDescription, shopDisplayName, shopFeatureDescription
          },
          shopItems{
            country, language, buyUrl, shopName
          },
          imageContent{
            defaultImageUrl,
            importedImages{
              country,
              imageUrl,
              shopImageUrl
            }
          },
          featuredPriorities{
            country, priority
            },
         isPurchasable,
          hubRequired,
          releaseDate,
          autoSync,
          releaseStatus,
          updatedTime,
          visibilityOs
          },
        }
      }`;

    case api_mode.SET_LINK_PRODUCT_CATALOGS_TO_STORY:
      return `mutation{
        setLinkProductCatalogsToStory(storyId: ${parameter.id}, productCatalogIdsToLink: [${parameter.merchandiseIds}]),{
          story{
            id,
            name,
            country,
            title,
            description,
            backgroundImageUrl,
            videoUrl,
            details{
              subTitle,
              imageUrl,
              description
            },
            displayEndTime,
            displayStartTime,
            releaseStatus,
            categoryIds,
            productCatalogCategoryIds
          },
          productCatalogListOf{
            id,
          uuid,
          modelCode,
          modelName,
          countries,
          brand{
            id, uuid, name, iconUrl, releaseStatus
          },
          categories{
            id, uuid, name, iconUrl,
            localizations{
              categoryId, locale, displayName, updatedTime
            }, visibilityOs, updatedTime
          },
          localizations{
            locale, displayName, featureDescription, shopDisplayName, shopFeatureDescription
          },
          shopItems{
            country, language, buyUrl, shopName
          },
          imageContent{
            defaultImageUrl,
            importedImages{
              country,
              imageUrl,
              shopImageUrl
            }
          },
          featuredPriorities{
          country, priority
          },
         isPurchasable,
          hubRequired,
          releaseDate,
          autoSync,
          releaseStatus,
          updatedTime,
          visibilityOs
          },
         categoryListOf{
          id,
          uuid,
          name,
          iconUrl,
          localizations{
            categoryId, locale, displayName, updatedTime
          },
          dossierCategoryNames,
          tags{
            id, name
          },
          visibilityOs
        }
        }
      }`;

    case api_mode.GET_PRODUCT_CATALOGS:
      return `query{
      productCatalog(id: ${parameter.id}),
      {
        id,
        uuid,
        modelCode,
        modelName,
        countries,
        brand{
          id, uuid, name, iconUrl, releaseStatus
        },
        categories{
          id, uuid, name, iconUrl,
          localizations{
            categoryId, locale, displayName, updatedTime
          }, visibilityOs, updatedTime
        },
        localizations{
          locale, displayName, featureDescription, shopDisplayName, shopFeatureDescription
        },
        shopItems{
          country, language, buyUrl, shopName
        },
        imageContent{
          defaultImageUrl,
          importedImages{
            country,
            imageUrl,
            shopImageUrl
          }
        }
       isPurchasable,
        hubRequired,
        releaseDate,
        autoSync,
        releaseStatus,
        updatedTime,
        visibilityOs,
        featuredPriorities{
          country,
          priority
        }
      }
    }`;

    case api_mode.DELETE_SINGLE_PRODUCT_CATALOGS:
      return `mutation{
        deleteProductCatalog(id: ${parameter})
      }`;

    case api_mode.DELETE_BULK_PRODUCT_CATALOGS:
      return `mutation{
        deleteBulkProductCatalogs(ids: [${parameter}]), {
          succeedIds
        }
      }`;

    case api_mode.UNPUBLISH_PRODUCT_CATALOGS:
      return `mutation{
        unpublishProductCatalogs(ids: [${parameter}]), {
          updatedIds,
          updatedStatus,
          totalCount
        }
      }`;

    case api_mode.CANCEL_PRODUCT_CATALOGS_APPROVAL_REQUESTED:
      return `mutation{
      cancelProductCatalogsApprovalRequest(ids: [${parameter}]), {
        updatedIds,
        updatedStatus,
        totalCount
      }
    }`;

    case api_mode.PUBLISH_PRODUCT_CATALOGS:
      return `mutation{
        publishProductCatalogs(ids: [${parameter}]), {
          updatedIds,
          updatedStatus,
          totalCount
        }
      }`;

    case api_mode.SUBMIT_PRODUCT_CATALOGS_FOR_APPROVAL:
      return `mutation{
        submitProductCatalogsForApproval(ids: [${parameter}]), {
          updatedIds,
          updatedStatus,
          totalCount
        }
      }`;

    case api_mode.GET_ALL_PRODUCT_CATALOG:
      return `{
            allProductCatalogs
              (
                pageNumber: ${parameter.pageNumber},
                filter: ${parameter.filter},
                orderBy : {
                  featured: true
                }
              )

              {
                  ${parameter.return_value}
              }
            }`;

    case api_mode.GET_ALL_PRODUCT_CATALOGS:
      return `{
        allProductCatalogs(
          pageNumber:${parameter.pageNumber},
          ${parameter.pageSize ? `pageSize: ${parameter.pageSize},` : ''}
          filter:{
            ${
              parameter.filter.country
                ? `
            country:"${parameter.filter.country}",`
                : ''
            }
            ${
              parameter.filter.releaseStatusIn === 'ALL'
                ? ''
                : `releaseStatusIn : [${parameter.filter.releaseStatusIn}],`
            }
            categoryIds:[${parameter.filter.categoryIds}],
            brandIds:[${parameter.filter.brandIds}],
            ${
              parameter.filter.serviceThemeIds
                ? `
            serviceThemeIds:[${parameter.filter.serviceThemeIds}],`
                : ''
            }
            ${
              parameter.filter.brandUuids
                ? `brandUuids: [${parameter.filter.brandUuids}],`
                : ''
            }
            ${
              parameter.filter.categoryUuids
                ? `categoryUuids: [${parameter.filter.categoryUuids}],`
                : ''
            }
            ${
              parameter.filter.searchBy
                ? `searchBy: "${parameter.filter.searchBy}",
                searchOption: [${parameter.filter.searchOption}]
          `
                : ''
            }
            ${
              parameter.filter.isAutoSync !== undefined
                ? `isAutoSync: ${parameter.filter.isAutoSync}`
                : ''
            }

          },
          orderBy : {${parameter.sortBy} : ${parameter.sortOrder}}

          ) {
            totalCount,
            pageInfo{totalPages},
            productCatalogs{
              id,
              uuid,
              modelCode,
              modelName,
              countries,
              brand{
                id, uuid, name, iconUrl, releaseStatus
              },
              categories{
                id, uuid, name, iconUrl,
                localizations{
                  categoryId, locale, displayName, updatedTime
                }, visibilityOs, updatedTime
              },
              localizations{
                locale, displayName, featureDescription, shopDisplayName, shopFeatureDescription
              },
              shopItems{
                country, language, buyUrl, shopName
              },
              imageContent{
                defaultImageUrl,
                importedImages{
                  country, imageUrl, shopImageUrl
                }
              },
              featuredPriorities{
                country, priority
              }
            isPurchasable,
              hubRequired,
              releaseDate,
              autoSync,
              releaseStatus,
              updatedTime,
              visibilityOs
              featuredPriorities{
                country,
                priority
              }
            }
          }
        }`;

    case api_mode.FIND_PRODUCT_CATALOGS:
      return `query{
      findProductCatalogs(uuid: "${parameter.uuid}"),{
        productCatalogs{
          id, uuid, modelCode, countries,
          brand{
            id, uuid, name, iconUrl, releaseStatus, updatedTime
          },
          categories{
            id, uuid, name, iconUrl, localizations{
              categoryId, locale, displayName, updatedTime
            }
          },
          localizations{
            locale, displayName, featureDescription, shopDisplayName, shopFeatureDescription
          },
          shopItems{
            country, language, buyUrl, shopName
          },
          hubRequired,
          releaseDate,
          autoSync,
          displayEndTime,
          displayStartTime,
          releaseStatus,
          updatedTime,
          visibilityOs
        }
      }
    }`;

    case api_mode.CREATE_BULK_PRODUCT_CATALOGS:
      return `mutation{
        createBulkProductCatalogs(inputs: [${parameter.inputs}]){
          succeedIds
        }
      }`;

    case api_mode.UPDATE_SINGLE_PRODUCT_CATALOGS:
      return `mutation{
          updateProductCatalog(
            input:{
              id: ${parameter.id},
              productCatalogInput: {
                modelCode:"${parameter.modelCode}",
                ${
                  parameter.uuid && parameter.uuid !== ''
                    ? 'uuid:"' + parameter.uuid + '",'
                    : ''
                }
                countries:[${parameter.countries}],
                brandId:${parameter.brandId},
                categoryIds:[${parameter.categoryIds}],
                modelName: "${parameter.modelName}",
                shopItems: [${parameter.shopItems}],
                hubRequired:${parameter.hubRequired},
                releaseDate: "${parameter.releaseDate}",
                imageContent: ${parameter.imageContent},
                autoSync: ${parameter.autoSync},
                visibilityOs: ${parameter.visibilityOs},
                localizations: [${parameter.localizations}],
                featuredPriorities: [${parameter.featuredPriorities}]
              }
            }
          ){
            id,
            modelCode,
            modelName,
            uuid,
            countries,
            brand{
              id, name,iconUrl
            },
            categories{
              id,name,iconUrl, localizations{
                categoryId,locale,displayName
              }
            },
            shopItems{
              country, language, buyUrl, shopName
            },
            localizations{
              locale, displayName, featureDescription, shopDisplayName, shopFeatureDescription
            },
            imageContent{
              defaultImageUrl,
              importedImages{
                country,
                imageUrl
              }
            }
            hubRequired,
            releaseDate,
            autoSync,
            releaseStatus,
            visibilityOs,
            updatedTime,
          }
        }`;

    case api_mode.ADD_SINGLE_PRODUCT_CATALOGS:
      return `mutation{
      createProductCatalog(
        input:{
          modelCode:"${parameter.modelCode}",
          ${
            parameter.uuid && parameter.uuid !== ''
              ? 'uuid:"' + parameter.uuid + '",'
              : ''
          }
          countries:"${parameter.countries}",
          brandId:${parameter.brandId},
          categoryIds:[${parameter.categoryIds}],
          modelName: "${parameter.modelName}",
          shopItems: [${parameter.shopItems}],
          hubRequired:${parameter.hubRequired},
          releaseDate: "${parameter.releaseDate}",
          imageContent: ${parameter.imageContent},
          autoSync: ${parameter.autoSync},
          visibilityOs: ${parameter.visibilityOs},
          localizations: [${parameter.localizations}],
          featuredPriorities: [${parameter.featuredPriorities}]
        }
      ){
        id,
        modelCode,
        modelName,
        uuid,
        countries,
        brand{
          id, name,iconUrl
        },
        categories{
          id,name,iconUrl, localizations{
            categoryId,locale,displayName
          }
        },
        shopItems{
          country, language, buyUrl, shopName
        },
        localizations{
          locale, displayName, featureDescription, shopDisplayName, shopFeatureDescription
        },
        imageContent{
          defaultImageUrl,
          importedImages{
            country,
            imageUrl
          }
        }
        hubRequired,
        releaseDate,
        autoSync,
        releaseStatus,
        visibilityOs,
        updatedTime,
      }
    }`;

    case api_mode.ADD_SINGLE_MERCHANDISE:
      return `mutation{
        createMerchandise(
          input:{
            modelCode:"${parameter.modelCode}",
            ${
              parameter.uuid && parameter.uuid !== ''
                ? 'uuid:"' + parameter.uuid + '",'
                : ''
            }
            country:"${parameter.country}",
            language:"${parameter.language}",
            brandId:${parameter.brandId},
            categoryIds:[${parameter.categoryIds}],
            ${
              parameter.serviceThemes
                ? 'serviceThemeIds:[' + parameter.serviceThemes + '],'
                : ''
            }
            tagIds:[${parameter.tagIds}]
            displayName: "${parameter.displayName}",
            featureDescription: "${parameter.featureDescription}",
            originalPrice: "${parameter.originalPrice}",
            currentPrice: "${parameter.currentPrice}",
            buyUrl: "${parameter.buyUrl}",
            imageUrl: "${parameter.imageUrl}",
            hubRequired:${parameter.hubRequired},
            releaseDate: "${parameter.releaseDate}",
            displayStartTime : "${parameter.displayStartTime}",
            ${
              parameter.displayEndTime
                ? 'displayEndTime :"' + parameter.displayEndTime + '",'
                : ''
            }
            autoSync: ${parameter.autoSync},
            visibilityOs: ${parameter.visibilityOs},
          }
        ){
          id,
          modelCode,
          uuid,
          country,
          language,
          brand{
            id, name,iconUrl
          },
          categories{
            id,name,iconUrl, localizations{
              categoryId,locale,displayName
            }
          },
          tags{
            id,name
          },
          serviceThemes{
            id,
            name,
            serviceCode,
            backgroundImageUrl,
            localizations{
              locale,
              title,
              message,
              description,
            },
            releaseStatus
          },
          displayName,
          featureDescription,
          originalPrice,
          currentPrice,
          buyUrl,
          imageUrl,
          hubRequired,
          releaseDate,
          displayStartTime ,
          displayEndTime,
          rank,
          autoSync,
          releaseStatus,
          visibilityOs,
          updatedTime
        }
      }`;
    case api_mode.UPDATE_SINGLE_MERCHANDISE:
      return `mutation{
        updateMerchandise(
          input : {
            id: ${parameter.id},
            merchandiseInput:{
              modelCode:"${parameter.modelCode}",
              ${
                parameter.uuid && parameter.uuid !== ''
                  ? 'uuid:"' + parameter.uuid + '",'
                  : ''
              }
              country:"${parameter.country}",
              language:"${parameter.language}",
              brandId:${parameter.brandId},
              categoryIds:[${parameter.categoryIds}],
              ${
                parameter.serviceThemes
                  ? 'serviceThemeIds:[' + parameter.serviceThemes + '],'
                  : ''
              }
              tagIds:[${parameter.tagIds}],
              displayName: "${parameter.displayName}",
              featureDescription: "${parameter.featureDescription}",
              originalPrice: "${parameter.originalPrice}",
              currentPrice: "${parameter.currentPrice}",
              buyUrl: "${parameter.buyUrl}",
              imageUrl: "${parameter.imageUrl}",
              hubRequired:${parameter.hubRequired},
              releaseDate: "${parameter.releaseDate}",
              displayStartTime : "${parameter.displayStartTime}",
              ${
                parameter.displayEndTime
                  ? 'displayEndTime :"' + parameter.displayEndTime + '",'
                  : ''
              }
              autoSync: ${parameter.autoSync},
              visibilityOs: ${parameter.visibilityOs},
            }
          }
        ){
          id,
          modelCode,
          uuid,
          country,
          language,
          brand{
            id, name,iconUrl
          },
          categories{
            id,name,iconUrl, localizations{
              categoryId,locale,displayName
            }
          },
          serviceThemes{
            id,
            name,
            serviceCode,
            backgroundImageUrl,
            localizations{
              locale,
              title,
              message,
              description,
            },
            releaseStatus
          },
          tags{
            id,name
          },
          displayName,
          featureDescription,
          originalPrice,
          currentPrice,
          buyUrl,
          imageUrl,
          hubRequired,
          releaseDate,
          displayStartTime ,
          displayEndTime,
          rank,
          autoSync,
          releaseStatus,
          visibilityOs,
          updatedTime
        }
      }`;
    case api_mode.DELETE_SINGLE_MERCHANDISE:
      return `mutation{
          deleteMerchandise(id : ${parameter.id})
      }`;
    case api_mode.ADD_BULK_MERCHANDISE:
      return `mutation{
        createBulkMerchandises(
          inputs :[${parameter.inputs}]
          ){
            succeedIds,
            failedIds,
            failedModelCodes
          }
      }`;
    case api_mode.UPDATE_BULK_MERCHANDISE:
      return `mutation{
        updateBulkMerchandises(
          inputs :[${parameter.inputs}]
          ){
            succeedIds,
            failedIds,
            failedModelCodes
          }
      }`;
    case api_mode.DELETE_BULK_MERCHANDISE:
      return `mutation{
        deleteBulkMerchandises(
          ids :[${parameter.ids}]
          ){
            deletedCount
          }
      }`;
    case api_mode.UPSERT_SINGLE_MERCHANDISE:
      return `mutation{
        upsertMerchandise(
          input:{
            id: ${parameter.id},
            merchandiseInput:{
              modelCode:"${parameter.modelCode}",
              ${
                parameter.uuid && parameter.uuid !== ''
                  ? 'uuid:"' + parameter.uuid + '",'
                  : ''
              }
              country:"${parameter.country}",
              language:"${parameter.language}",
              brandId:${parameter.brandId},
              categoryIds:[${parameter.categoryIds}],
              ${
                parameter.serviceThemes
                  ? 'serviceThemeIds:["' + parameter.serviceThemes + '"],'
                  : ''
              }
              tagIds:[${parameter.tagIds}],
              displayName: "${parameter.displayName}",
              featureDescription: "${parameter.featureDescription}",
              originalPrice: "${parameter.originalPrice}",
              currentPrice: "${parameter.currentPrice}",
              buyUrl: "${parameter.buyUrl}",
              imageUrl: "${parameter.imageUrl}",
              hubRequired:${parameter.hubRequired},
              releaseDate: "${parameter.releaseDate}",
              displayStartTime : "${parameter.displayStartTime}",
              ${
                parameter.displayEndTime
                  ? 'displayEndTime :"' + parameter.displayEndTime + '",'
                  : ''
              }
              autoSync: ${parameter.autoSync},
            }
            releaseStatus : ${parameter.releaseStatus}
          }
        ){
          id,
          modelCode,
          uuid,
          country,
          language,
          brand{
            id, name,iconUrl
          },
          categories{
            id,name,iconUrl, localizations{
              categoryId,locale,displayName
            }
          },
          serviceThemes{
            id,
            name,
            serviceCode,
            backgroundImageUrl,
            localizations{
              locale,
              title,
              message,
              description,
            },
            releaseStatus
          },
          tags{
            id,name
          },
          displayName,
          featureDescription,
          originalPrice,
          currentPrice,
          buyUrl,
          imageUrl,
          hubRequired,
          releaseDate,
          displayStartTime ,
          displayEndTime,
          rank,
          autoSync,
          releaseStatus,
          updatedTime
        }
      }`;
    case api_mode.UPSERT_BULK_MERCHANDISE:
      return `mutation{
        upsertBulkMerchandises(
          inputs :[${parameter.inputs}]
          ){
            succeedIds,
            failedIds,
            failedModelCodes
          }
      }`;
    case api_mode.SUBMIT_MERCHANDISE:
      return `mutation{
        submitMerchandisesForApproval(
          input :{
            merchandiseIds :[${parameter.merchandisesIds}]
          }
          ){
            totalCount,
            updatedMerchandiseIds,
            updatedStatus
          }
      }`;
    case api_mode.PUBLISH_MERCHANDISE:
      return `mutation{
        publishMerchandises(
          input :{
            merchandiseIds :[${parameter.merchandisesIds}]
          }
          ){
            publishedCount,
            readyToPublishCount,
            publishedMerchandiseIds,
            readyToPublishMerchandiseIds,
          }
      }`;
    case api_mode.CANCEL_MERCHANDISE:
      return `mutation{
        cancelMerchandises(
          input :{
            merchandiseIds :[${parameter.merchandisesIds}]
          }
          ){
            totalCount,
            updatedMerchandiseIds,
            updatedStatus
          }
      }`;
    case api_mode.UNPUBLISH_MERCHANDISE:
      return `mutation{
        unpublishMerchandises(
          input :{
            merchandiseIds :[${parameter.merchandisesIds}]
          }
          ){
            totalCount,
            updatedMerchandiseIds,
            updatedStatus
          }
      }`;
    case api_mode.CLOSE_MERCHANDISE:
      return `mutation{
        closeBulkMerchandises(
          input :{
            merchandiseIds :[${parameter.merchandisesIds}]
          }
          ){
            totalCount,
            closedMerchandiseIds
          }
      }`;
    case api_mode.GET_ALL_MERCHANDISES:
      return `{
          allMerchandises
            (
              pageNumber: ${parameter.pageNumber},
              filter: ${parameter.filter},
              sort : ${parameter.sort}
            )

            {
              totalCount,
              pageInfo{
                  totalPages
              },
              merchandises{
                ${parameter.return_value}
              }
            }
          }`;
    case api_mode.GET_ALL_MERCHANDISE: //TODO set search/filter parameter
      return `{
        allMerchandises(
          pageNumber:${parameter.pageNumber},
          ${parameter.pageSize ? `pageSize: ${parameter.pageSize},` : ''}
          filter:{
            ${
              parameter.filter.country
                ? `
            country:"${parameter.filter.country}",`
                : ''
            }
            ${
              parameter.filter.releaseStatusIn === 'ALL'
                ? ''
                : `releaseStatusIn : [${parameter.filter.releaseStatusIn}],`
            }
            categoryIds:[${parameter.filter.categoryIds}],
            brandIds:[${parameter.filter.brandIds}],
            ${
              parameter.filter.serviceThemeIds
                ? `
            serviceThemeIds:[${parameter.filter.serviceThemeIds}],`
                : ''
            }
            ${
              parameter.filter.brandUuids
                ? `brandUuids: [${parameter.filter.brandUuids}],`
                : ''
            }
            ${
              parameter.filter.categoryUuids
                ? `categoryUuids: [${parameter.filter.categoryUuids}],`
                : ''
            }
            ${
              parameter.searchBy
                ? `searchBy: "${parameter.searchBy}",
            searchOption:{
              modelCode:${parameter.filter.searchOption.modelCode},
              displayName:${parameter.filter.searchOption.displayName},
              brandName:${parameter.filter.searchOption.brandName},
              categoryDisplayName:${parameter.filter.searchOption.categoryDisplayName},
              merchandiseTag:${parameter.filter.searchOption.merchandiseTag},
              modelName:${parameter.filter.searchOption.modelName}
            }
          `
                : ''
            }
          },
          sort : {${parameter.sortBy} : ${parameter.sortOrder}}
          ) {
            totalCount,
            pageInfo{totalPages},
            merchandises{
              id,
              modelCode,
              uuid,
              country,
              language,
              brand{
                id, name,iconUrl,uuid
              },
              categories{
                id,name,iconUrl,uuid localizations{
                  categoryId,locale,displayName
                }
              },
              serviceThemes{
                id,
                name,
                serviceCode,
                backgroundImageUrl,
                localizations{
                  locale,
                  title,
                  message,
                  description,
                },
                releaseStatus
              },
              tags{
                id,name
              },
              displayName,
              featureDescription,
              originalPrice,
              currentPrice,
              displayOriginalPrice,
              displayCurrentPrice,
              buyUrl,
              imageUrl,
              hubRequired,
              releaseDate,
              displayStartTime ,
              displayEndTime,
              rank,
              autoSync,
              releaseStatus,
              updatedTime,
              visibilityOs
            }
          }
        }`;
    case api_mode.GET_SINGLE_MERCHANDISE:
      return `{
          merchandise(id:${parameter.id}){
            id,
            modelCode,
            uuid,
            country,
            language,
            brand{
              id, name,iconUrl
            },
            categories{
              id,name,iconUrl, localizations{
                categoryId,locale,displayName
              }
            },
            serviceThemes{
              id,
              name,
              serviceCode,
              backgroundImageUrl,
              localizations{
                locale,
                title,
                message,
                description,
              },
              releaseStatus
            },
            tags{
              id,name
            },
            displayName,
            featureDescription,
            originalPrice,
            currentPrice,
            displayOriginalPrice,
            displayCurrentPrice,
            buyUrl,
            imageUrl,
            hubRequired,
            releaseDate,
            displayStartTime ,
            displayEndTime,
            rank,
            autoSync,
            releaseStatus,
            updatedTime,
            visibilityOs
          }
        }`;
    case api_mode.GET_SINGLE_CLOSED_MERCHANDISE:
      return `{
          closedMerchandise(id:${parameter.id}){
            closedId,
            snapshot{
              id,
              modelCode,
              uuid,
              country,
              language,
              brand{
                id, name,iconUrl
              },
              categories{
                id,name,iconUrl, localizations{
                  categoryId,locale,displayName
                }
              },
              serviceThemes{
                id,
                name,
                serviceCode,
                backgroundImageUrl,
                localizations{
                  locale,
                  title,
                  message,
                  description,
                },
                releaseStatus
              },
              tags{
                id,name
              },
              displayName,
              featureDescription,
              originalPrice,
              currentPrice,
              displayOriginalPrice,
              displayCurrentPrice,
              buyUrl,
              imageUrl,
              hubRequired,
              releaseDate,
              displayStartTime ,
              displayEndTime,
              rank,
              autoSync,
              releaseStatus,
              updatedTime,
              visibilityOs
            }
          }
        }`;
    case api_mode.GET_CLOSE_MERCHANDISE:
      return `{
        allClosedMerchandises(
          ${parameter.pageNumber ? `pageNumber: ${parameter.pageNumber},` : ''}
          ${
            parameter.pageSize
              ? `pageSize: ${parameter.pageSize},`
              : 'pageSize: 9999,'
          }
          filter:{
            country:"${parameter.filter.country}",
            ${
              parameter.searchBy && parameter.filter.searchOption.modelCode
                ? `modelCodeContains: "${parameter.searchBy}",
          `
                : ''
            }
          },
          ) {
            totalCount,
            pageInfo{totalPages},
            closedMerchandises{
              id,
              modelCode,
              uuid,
              country,
              language,
              brand{
                id, name,iconUrl
              },
              categories{
                id,name,iconUrl, localizations{
                  categoryId,locale,displayName
                }
              },
              serviceThemes{
                id,
                name,
                serviceCode,
                backgroundImageUrl,
                localizations{
                  locale,
                  title,
                  message,
                  description,
                },
                releaseStatus
              },
              tags{
                id,name
              },
              displayName,
              featureDescription,
              originalPrice,
              currentPrice,
              displayOriginalPrice,
              displayCurrentPrice,
              buyUrl,
              imageUrl,
              hubRequired,
              releaseDate,
              displayStartTime ,
              displayEndTime,
              rank,
              autoSync,
              releaseStatus,
              updatedTime
            }
          }
        }`;
    case api_mode.EDIT_MERCHANDISE_RANK_ORDERING:
      return `mutation{
        editMerchandiseRankOrdering(
          ${parameter.input}
        )
        {
          totalCount,
          pageInfo{
              totalPages,currentPage
          },
          merchandises{id,rank}
        }
      }`;
    case api_mode.GET_SINGLE_STORY:
      return `{
        story(
              id: ${parameter.id}
        ) {
          story{
                  id,
                  name,
                  title,
                  description,
                  backgroundImageUrl,
                  videoUrl,
                  displayEndTime,
                  displayStartTime,
                  releaseStatus,
                  country,
                  categoryIds,
                  featuredPriority,
                  updatedTime,
                  details{
                    subTitle,
                    imageUrl,
                    description
                  }
          },
          productCatalogListOf{
          id,
          uuid,
          modelCode,
          modelName,
          countries,
          brand{
            id, uuid, name, iconUrl, releaseStatus
          },
          categories{
            id, uuid, name, iconUrl,
            localizations{
              categoryId, locale, displayName, updatedTime
            }, visibilityOs, updatedTime
          },
          localizations{
            locale, displayName, featureDescription, shopDisplayName, shopFeatureDescription
          },
          shopItems{
            country, language, buyUrl, shopName
          },
          imageContent{
            defaultImageUrl,
            importedImages{
              country,
              imageUrl,
              shopImageUrl
            }
          },
          featuredPriorities{
            country, priority
            },
         isPurchasable,
          hubRequired,
          releaseDate,
          autoSync,
          releaseStatus,
          updatedTime,
          visibilityOs
          },
          }
      }`;
    case api_mode.CREATE_STORY:
      return `mutation{
        createStory(
          input :{
            story : ${parameter.query}
          }
          ){
            story{
                id
            }
          }
      }`;
    case api_mode.UPDATE_STORY:
      return `mutation{
        updateStory(
          input :{
            id: ${parameter.id} ,
            story : ${parameter.query}
          }
          ){
            story{
                id
            }
          }
      }`;
    case api_mode.LINK_MERCHANDISES_TO_STORY:
      return `mutation {
        linkMerchandisesToStory(
          storyId: ${parameter.id}
          merchandiseIdsToLink: [${parameter.merchandiseIds}],
        )
        {
          story{
            id
          },
          merchandiseListOf{
              id,
              modelCode,
              country,
              language
          }
        }
      }`;
    case api_mode.UNLINK_MERCHANDISES_FROM_STORY:
      return `mutation {
          unlinkMerchandisesFromStory(
            storyId: ${parameter.id}
            merchandiseIdsToUnlink: [${parameter.merchandiseIds}],
          )
          {
            story{
              id
            },
            merchandiseListOf{
                id,
                modelCode,
                country,
                language
            }
          }
        }`;
    case api_mode.DELETE_SINGLE_STORY:
      return `mutation{
        deleteStory (id : ${parameter.storyID})
      }`;
    case api_mode.SUBMIT_SINGLE_STORY:
      return `mutation{
        submitStoryForApproval (id : ${parameter.storyID})
        {
            story{
                id,
                releaseStatus
            }
        }
      }`;
    case api_mode.PUBLISH_SINGLE_STORY:
      return `mutation{
        publishStory (id : ${parameter.storyID})
        {
            story{
                id,
                releaseStatus
            }
        }
      }`;
    case api_mode.CANCEL_SINGLE_STORY:
      return `mutation{
        cancelStory (id : ${parameter.storyID})
        {
            story{
                id,
                releaseStatus
            }
        }
      }`;
    case api_mode.UNPUBLISH_SINGLE_STORY:
      return `mutation{
        unpublishStory (id : ${parameter.storyID})
        {
            story{
                id,
                releaseStatus
            }
        }
      }`;
    case api_mode.EDIT_STORY_ORDERING:
      return `mutation{
        editStoryOrdering(
          input : ${parameter.input}
          )
          {
          totalCount,pageInfo{totalPages},
          stories{
              id,
              name,
              country
              title,
              description,
              backgroundImageUrl,
              videoUrl,
              displayEndTime,
              displayStartTime,
              releaseStatus,
              categoryIds,
              featuredPriority,
              updatedTime
          }
        }
      }`;
    case api_mode.UPLOAD_IMAGE_URL:
      return `mutation{
        createPresignedS3Url(
          objectKey :"${parameter.objectKey}"
          ){
            url
          }
      }`;

    case api_mode.GET_ALL_SUPER_CATEGORIES_LITE:
      return `{
          allSuperCategories{
            superCategories{
              id,name
            }
          }
        }`;
    default:
      return ``;
  }
};
