export const api_mode = {
  GET_ALL_CATEGORIES_WITHOUT_PAGINATION: 'allCategoriesWithoutPagination',
  GET_ALL_CATEGORIES: 'allCategories',
  DELETE_SINGLE_CATEGORY: 'deleteCategory',
  GET_SINGLE_CATEGORY: 'category',
  INSERT_SINGLE_CATEGORY: 'insertCategory',
  UPDATE_SINGLE_CATEGORY: 'updateCategory',
  GET_ALL_THEMES_WITHOUT_PAGINATION: 'allThemesWithoutPagination',
  GET_ALL_THEMES: 'allThemes',
  GET_ALL_THEMES_EXPORT: 'allThemesExp',
  GET_ALL_STORIES: 'allStories',
  GET_ALL_BRANDS_WITHOUT_PAGINATION: 'allBrandsWithoutPagination',
  GET_ALL_TAGS_WITHOUT_PAGINATION: 'allTags',
  ADD_SINGLE_LIFESTYLE: 'createServiceTheme',
  UPDATE_SINGLE_LIFESTYLE: 'updateServiceTheme',
  UPSERT_SINGLE_LIFESTYLE: 'upsertServiceTheme',
  LINK_MERCHANDISES_TO_LIFESTYLE: 'linkMerchandisesToServiceTheme',
  UNLINK_MERCHANDISES_FROM_LIFESTYLE: 'unlinkMerchandisesFromServiceTheme',
  SUBMIT_SINGLE_LIFESTYLE: 'submitServiceThemeForApproval',
  CANCEL_SINGLE_LIFESTYLE: 'cancelServiceTheme',
  PUBLISH_SINGLE_LIFESTYLE: 'publishServiceTheme',
  UNPUBLISH_SINGLE_LIFESTYLE: 'unpublishServiceTheme',
  DELETE_SINGLE_LIFESTYLE: 'deleteServiceTheme',
  GET_SINGLE_LIFESTYLE: 'serviceTheme',
  ADD_BULK_TAGS: 'createBulkTags',
  ADD_SINGLE_MANAGED_PRODUCT: 'upsertManagedProduct',
  DELETE_SINGLE_MANAGED_PRODUCT: 'deleteManagedProduct',
  GET_ALL_MANAGED_PRODUCT: 'allManagedProducts',

  ADD_SINGLE_MERCHANDISE: 'createMerchandise',
  UPDATE_SINGLE_MERCHANDISE: 'updateMerchandise',
  DELETE_SINGLE_MERCHANDISE: 'deleteMerchandise',
  UPSERT_SINGLE_MERCHANDISE: 'upsertMerchandise',
  ADD_BULK_MERCHANDISE: 'createBulkMerchandises',
  UPDATE_BULK_MERCHANDISE: 'updateBulkMerchandises',
  DELETE_BULK_MERCHANDISE: 'deleteBulkMerchandises',
  UPSERT_BULK_MERCHANDISE: 'upsertBulkMerchandises',

  SUBMIT_MERCHANDISE: 'submitMerchandisesForApproval',
  PUBLISH_MERCHANDISE: 'publishMerchandises',
  CANCEL_MERCHANDISE: 'cancelMerchandises',
  UNPUBLISH_MERCHANDISE: 'unpublishMerchandises',
  CLOSE_MERCHANDISE: 'closeBulkMerchandises',
  GET_SINGLE_MERCHANDISE: 'merchandise',
  GET_SINGLE_CLOSED_MERCHANDISE: 'closeMerchandise',
  GET_ALL_MERCHANDISE: 'allMerchandises',
  GET_ALL_MERCHANDISES: 'allMerchandisess',
  GET_CLOSE_MERCHANDISE: 'allClosedMerchandises',
  EDIT_MERCHANDISE_RANK_ORDERING: 'editMerchandiseRankOrdering',

  CREATE_STORY: 'createStory',
  UPDATE_STORY: 'updateStory',
  GET_SINGLE_STORY: 'story',
  LINK_MERCHANDISES_TO_STORY: 'linkMerchandisesToStory',
  UNLINK_MERCHANDISES_FROM_STORY: 'unlinkMerchandisesFromStory',
  DELETE_SINGLE_STORY: 'deleteStory',
  SUBMIT_SINGLE_STORY: 'submitStoryForApproval',
  PUBLISH_SINGLE_STORY: 'publishStory',
  CANCEL_SINGLE_STORY: 'cancelStory',
  UNPUBLISH_SINGLE_STORY: 'unpublishStory',
  EDIT_STORY_ORDERING: 'editStoryOrdering',

  UPLOAD_IMAGE_URL: 'createPresignedS3Url',

  //Brand stuffs
  GET_ALL_BRANDS_WITH_PARAMS: 'allBrandsWithParams',
  GET_SINGLE_BRAND: 'getBrandById',
  CREATE_SINGLE_BRAND_DRAFT: 'createBrandDraft',
  SAVE_SINGLE_BRAND_DRAFT: 'updateBrandDraft',
  DELETE_SINGLE_BRAND: 'deleteBrandById',
  SUBMIT_BRAND_FOR_APPROVAL: 'submitBrandForApproval',
  CANCEL_REQUESTED_BRAND: 'cancelRequestedBrand',
  APPROVE_REQUESTED_BRAND: 'approveRequestedBrand',
  UNPUBLISH_PUBLISHED_BRAND: 'unpublishPublishedBrand',

  //Dossier
  GET_ALL_DOSSIER: 'dossierCategories',
  SET_DOSSIER_CATEGORY_MAPPING: 'setDossierCategoryMapping',

  ADD_SINGLE_PRODUCT_CATALOGS: 'createProductCatalog',
  UPDATE_SINGLE_PRODUCT_CATALOGS: 'updateProductCatalog',
  GET_ALL_PRODUCT_CATALOGS: 'allProductCatalogs',
  GET_ALL_PRODUCT_CATALOG: 'allProductCatalog',
  FIND_PRODUCT_CATALOGS: 'findProductCatalogs',
  SUBMIT_PRODUCT_CATALOGS_FOR_APPROVAL: 'submitProductCatalogsForApproval',
  PUBLISH_PRODUCT_CATALOGS: 'publishProductCatalogs',
  CANCEL_PRODUCT_CATALOGS_APPROVAL_REQUESTED:
    'cancelProductCatalogsApprovalRequest',
  UNPUBLISH_PRODUCT_CATALOGS: 'unpublishProductCatalogs',
  CREATE_BULK_PRODUCT_CATALOGS: 'createBulkProductCatalogs',
  DELETE_BULK_PRODUCT_CATALOGS: 'deleteBulkProductCatalogs',
  GET_PRODUCT_CATALOGS: 'productCatalog',
  SET_LINK_PRODUCT_CATALOGS_TO_STORY: 'setLinkProductCatalogsToStory',
  SET_LINK_PRODUCT_CATALOGS_TO_SERVICE_THEME:
    'setLinkProductCatalogsToServiceTheme',
  DELETE_SINGLE_PRODUCT_CATALOGS: 'deleteProductCatalog',

  // Super Categories
  GET_ALL_SUPER_CATEGORIES: 'allSuperCategories',
  DELETE_SUPER_CATEGORY: 'deleteSuperCategory',
  UPSERT_SUPER_CATEGORY: 'upsertSuperCategory',
  GET_DETAIL_SUPER_CATEGORY: 'superCategory',
  GET_ALL_SUPER_CATEGORIES_LITE: 'allSuperCategoriesLite',
};

export type BrandRepresentation = {
  name: string;
  id: number;
  uuid: string;
  iconUrl: string;
};

export const ACTION_TYPE = {
  DELETE: 'delete',
  SUBMIT: 'submit',
  APPROVE: 'approve',
  REJECT: 'reject',
  PUBLISH: 'publish',
  EDIT: 'edit',
  CLOSE: 'close',
  UNPUBLISH: 'unpublish',
  CREATE: 'create',
  EXPORT: 'export',
  PREVIEW: 'preview',
};

export const STATUS_TYPE = {
  DRAFT: 'draft',
  PENDING: 'pending',
  REQUESTED: 'requested',
  APPROVED: 'approved',
  CLOSED: 'closed',
  PUBLISHED: 'published',
  FEATURED: 'featured',
  PUBLISHED_BRAND: 'published_brand',
};

export const ACTION_TYPE_MSG = {
  [ACTION_TYPE.SUBMIT]: 'submited',
  [ACTION_TYPE.APPROVE]: 'approved',
  [ACTION_TYPE.REJECT]: 'rejected',
  [ACTION_TYPE.DELETE]: 'deleted',
  [ACTION_TYPE.EDIT]: 'edited',
  [ACTION_TYPE.CREATE]: 'created',
  [ACTION_TYPE.PUBLISH]: 'published',
  [ACTION_TYPE.UNPUBLISH]: 'unpublished',
  [ACTION_TYPE.CLOSE]: 'closed',
};

export const additionalDIDMapping = {
  en: 'en-GB',
  sq: 'sq-AL',
  ar: 'ar-AE',
  hy: 'hy-AM',
  as: 'as-IN',
  az: 'az-AZ',
  eu: 'eu-ES',
  be: 'be-BY',
  bn: 'bn-IN',
  bg: 'bg-BG',
  bo: 'bo-XX',
  bs: 'bs-BA',
  ca: 'ca-ES',
  zh: 'zh-CN',
  hr: 'hr-HR',
  cs: 'cs-CZ',
  da: 'da-DK',
  nl: 'nl-NL',
  et: 'et-EE',
  fa: 'fa-IR',
  tl: 'tl-PH',
  fi: 'fi-FI',
  fr: 'fr-FR',
  gl: 'gl-ES',
  ka: 'ka-GE',
  de: 'de-DE',
  el: 'el-GR',
  gu: 'gu-IN',
  iw: 'he-IL',
  he: 'he-IL',
  hi: 'hi-IN',
  hu: 'hu-HU',
  is: 'is-IS',
  in: 'in-ID',
  ga: 'ga-IE',
  it: 'it-IT',
  ja: 'ja-JP',
  kn: 'kn-IN',
  kk: 'kk-KZ',
  km: 'km-KH',
  ko: 'ko-KR',
  ky: 'ky-KG',
  lo: 'lo-LA',
  lv: 'lv-LV',
  lt: 'lt-LT',
  mk: 'mk-MK',
  ms: 'ms-MY',
  ml: 'ml-IN',
  mr: 'mr-IN',
  mn: 'mn-MN',
  my: 'my-ZG',
  // "ne": "ne-NP",
  ne: 'ne-IN',
  nb: 'nb-NO',
  or: 'or-IN',
  pl: 'pl-PL',
  pt: 'pt-PT',
  pa: 'pa-IN',
  ro: 'ro-RO',
  ru: 'ru-RU',
  sr: 'sr-RS',
  // "si": "si-LK",
  sk: 'sk-SK',
  sl: 'sl-SI',
  es: 'es-ES',
  sv: 'sv-SE',
  tg: 'tg-TJ',
  ta: 'ta-IN',
  te: 'te-IN',
  tk: 'tk-TM',
  th: 'th-TH',
  tr: 'tr-TR',
  uk: 'uk-UA',
  ur: 'ur-PK',
  ug: 'ug-CN',
  uz: 'uz-UZ',
  vi: 'vi-VN',
};
