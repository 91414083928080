import { ACTION_TYPE, STATUS_TYPE } from './common';

const {
  DELETE,
  SUBMIT,
  APPROVE,
  REJECT,
  PUBLISH,
  EDIT,
  CLOSE,
  UNPUBLISH,
} = ACTION_TYPE;

const {
  DRAFT,
  PENDING,
  REQUESTED,
  APPROVED,
  CLOSED,
  PUBLISHED,
  PUBLISHED_BRAND,
} = STATUS_TYPE;

export const ROLE_TYPE = {
  MALL_MARKETER: 'MALL_MARKETER',
  MALL_PORTAL_MANAGER: 'MALL_PORTAL_MANAGER',
  MALL_ACCOUNT_MANAGER: 'MALL_ACCOUNT_MANAGER',
  MALL_READ_ONLY: 'MALL_READ_ONLY',
  // MALL_ADMIN: 'MALL_ADMIN',
};

export const VDA_ROLE_TYPE = {
  VDA_ADMIN: 'VDA_ADMIN',
  VDA_MEMBERS: 'VDA_MEMBERS',
  VDA_READ_ONLY: 'VDA_READ_ONLY',
};

export const ASSETS_ROLE_TYPE = {
  ASSETS_MANAGER: 'ASSETS_MANAGER',
  ASSETS_USER: 'ASSETS_USER',
  ASSETS_READ_ONLY: 'ASSETS_READ_ONLY',
};
export const EXPLORE_ROLE_TYPE = {
  EXPLORE_MANAGER: 'EXPLORE_MANAGER',
  EXPLORE_USER: 'EXPLORE_USER',
  EXPLORE_READ_ONLY: 'EXPLORE_READ_ONLY',
};

export const ROLE_ACCESS = {
  STATUS_ACTIONS: {
    // [ROLE_TYPE.MALL_ADMIN]: {
    //   [DRAFT]: [EDIT, DELETE, SUBMIT],
    //   [PENDING]: [REJECT, APPROVE],
    //   [REQUESTED]: [REJECT, APPROVE],
    //   [APPROVED]: [PUBLISH],
    //   [CLOSED]: [],
    //   [PUBLISHED]: [CLOSE, UNPUBLISH],
    // },
    [ROLE_TYPE.MALL_ACCOUNT_MANAGER]: {
      [DRAFT]: [EDIT, DELETE, SUBMIT],
      [PENDING]: [REJECT, APPROVE],
      [REQUESTED]: [REJECT, APPROVE],
      [APPROVED]: [PUBLISH],
      [CLOSED]: [],
      [PUBLISHED]: [CLOSE, UNPUBLISH, EDIT],
      [PUBLISHED_BRAND]: [DELETE],
    },
    [ROLE_TYPE.MALL_PORTAL_MANAGER]: {
      [DRAFT]: [EDIT, DELETE, SUBMIT],
      [PENDING]: [REJECT, APPROVE],
      [REQUESTED]: [REJECT, APPROVE],
      [APPROVED]: [PUBLISH],
      [CLOSED]: [],
      [PUBLISHED]: [CLOSE, UNPUBLISH, EDIT],
      [PUBLISHED_BRAND]: [DELETE],
      // [PUBLISHED]: [EDIT, DELETE]
    },
    [ROLE_TYPE.MALL_MARKETER]: {
      [DRAFT]: [EDIT, SUBMIT],
      [PENDING]: [],
      [REQUESTED]: [],
      [APPROVED]: [],
      [CLOSED]: [],
      [PUBLISHED]: [],
      [PUBLISHED_BRAND]: [],
    },
  },
};
