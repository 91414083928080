export const LANGUAGE_LIST = [
  { value: 'en-US', label: 'English (United States of America)' },
  { label: 'Korean (Korea)', value: 'ko-KR' },
  {
    value: 'en-GB',
    label: 'English (United Kingdom of Great Britain and Northern Ireland)',
  },
  { label: 'Bulgarian (Bulgaria)', value: 'bg-BG' },
  { label: 'Bengali (Bangladesh)', value: 'bn-BD' },
  { label: 'Czech (Czech Republic)', value: 'cs-CZ' },
  { label: 'Danish (Denmark)', value: 'da-DK' },
  { label: 'German (Germany)', value: 'de-DE' },
  { label: 'Greek (Greece)', value: 'el-GR' },
  { label: 'Spanish (Castilian)', value: 'es-ES' },
  { label: 'Spanish(Latin)', value: 'es-US' },
  { label: 'Basque (Spain)', value: 'eu-ES' },
  { label: 'Finnish (Finland)', value: 'fi-FI' },
  { label: 'French (France)', value: 'fr-FR' },
  { label: 'French (Canada)', value: 'fr-CA' },
  { label: 'Galician (Spain)', value: 'gl-ES' },
  { label: 'Croatian (Croatia)', value: 'hr-HR' },
  { label: 'Hungarian (Hungary)', value: 'hu-HU' },
  { label: 'Italian (Italy)', value: 'it-IT' },
  { label: 'Norwegian (Bokm?l) (Norway)', value: 'nb-NO' },
  { label: 'Dutch (Netherlands)', value: 'nl-NL' },
  { label: 'Polish (Poland)', value: 'pl-PL' },
  { label: 'Portuguese (Brazil)', value: 'pt-BR' },
  { label: 'Portuguese (Portugal)', value: 'pt-PT' },
  { label: 'Romanian (Romania)', value: 'ro-RO' },
  { label: 'Slovak (Slovakia)', value: 'sk-SK' },
  { label: 'Slovenian (Slovenia)', value: 'sl-SI' },
  { label: 'Albanian (Albania)', value: 'sq-AL' },
  { label: 'Serbian (Cyrillic)', value: 'sr-Cyrl-RS' },
  { label: 'Swedish (Sweden)', value: 'sv-SE' },
  { label: 'Estonian (Estonia)', value: 'et-EE' },
  { label: 'Chinese (S)', value: 'zh-CN' },
  { label: 'Russian (Russia)', value: 'ru-RU' },
  { label: 'Arabic (U.A.E.)', value: 'ar-AE' },
  { label: 'Thai (Thailand)', value: 'th-TH' },
  { label: 'Turkish (Turkey)', value: 'tr-TR' },
  //addition for 34 languages above
  { value: 'en-CA', label: 'English (Canada)' },
  { value: 'hy-AM', label: 'Armenian (Armenia)' },
  { value: 'as-IN', label: 'Assamese (India)' },
  { value: 'be-BY', label: 'Belarusian (Belarus)' },
  { value: 'bn-IN', label: 'Bengali (India)' },
  { value: 'ca-ES', label: 'Catalan; Valencian (Spain)' },
  { value: 'fa-IR', label: 'Persian (Iran (Islamic Republic of))' },
  { value: 'ka-GE', label: 'Georgian (Georgia)' },
  { value: 'gu-IN', label: 'Gujarati (India)' },
  { value: 'he-IL', label: 'Hebrew (Israel)' },
  { value: 'hi-IN', label: 'Hindi (India)' },
  { value: 'is-IS', label: 'Icelandic (Iceland)' },
  { value: 'ga-IE', label: 'Irish (Ireland)' },
  { value: 'ja-JP', label: 'Japanese (Japan)' },
  { value: 'kn-IN', label: 'Kannada (India)' },
  { value: 'kk-KZ', label: 'Kazakh (Kazakhstan)' },
  { value: 'km-KH', label: 'Central Khmer (Cambodia)' },
  { value: 'ky-KG', label: 'Kirghiz (Kyrgyzstan)' },
  { value: 'lo-LA', label: "Lao (Lao People's Democratic Republic)" },
  { value: 'lv-LV', label: 'Latvian (Latvia)' },
  { value: 'lt-LT', label: 'Lithuanian (Lithuania)' },
  {
    value: 'mk-MK',
    label: 'Macedonian (The former Yugoslav Republic of Macedonia)',
  },
  { value: 'ms-MY', label: 'Malay (Malaysia)' },
  { value: 'ml-IN', label: 'Malayalam (India)' },
  { value: 'mr-IN', label: 'Marathi (India)' },
  { value: 'my-MM', label: 'Burmese (Myanmar)' },
  { value: 'ne-IN', label: 'Nepali (India)' },
  { value: 'or-IN', label: 'Oriya (India)' },
  { value: 'tg-TJ', label: 'Tajik (Tajikistan)' },
  { value: 'ta-IN', label: 'Tamil (India)' },
  { value: 'te-IN', label: 'Telugu (India)' },
  { value: 'tk-TM', label: 'Turkmen (Turkmenistan)' },
  { value: 'uk-UA', label: 'Ukrainian (Ukraine)' },
  { value: 'ur-PK', label: 'Urdu (Pakistan)' },
  { value: 'ug-CN', label: 'Uyghur (China)' },
  { value: 'vi-VN', label: 'Vietnamese (Viet Nam)' },
];
export const LANGUAGE_IDS = (() => LANGUAGE_LIST.map(lang => lang.value))();
export const LANGUAGE_NAME = (() =>
  LANGUAGE_LIST.reduce((acc, lang) => {
    acc[lang.value] = lang.label;
    return acc;
  }, {}))();
