import Keycloak from 'keycloak-js';
import {
  MALL_KEYCLOAK_IDP,
  MALL_KEYCLOAK_HOST,
  MALL_KEYCLOAK_REALM,
  MALL_KEYCLOAK_CLIENTID,
  RECOM_KEYCLOAK_HOST,
  RECOM_KEYCLOAK_REALM,
  RECOM_KEYCLOAK_CLIENTID,
  GSE_KEYCLOAK_IDP,
  GSE_KEYCLOAK_HOST,
  GSE_KEYCLOAK_REALM,
  GSE_KEYCLOAK_CLIENTID,
} from './constants';

interface KeycloakConfigExt extends Keycloak.KeycloakConfig {
  idpHint?: string;
}

const configMALL: KeycloakConfigExt = {
  // idpHint: 'google',
  url: MALL_KEYCLOAK_HOST,
  realm: MALL_KEYCLOAK_REALM,
  clientId: MALL_KEYCLOAK_CLIENTID,
};
const configRECOM: KeycloakConfigExt = {
  url: RECOM_KEYCLOAK_HOST,
  realm: RECOM_KEYCLOAK_REALM,
  clientId: RECOM_KEYCLOAK_CLIENTID,
};
const configGSE: KeycloakConfigExt = {
  // idpHint: 'keycloak-oidc', // optional
  url: GSE_KEYCLOAK_HOST,
  realm: GSE_KEYCLOAK_REALM,
  clientId: GSE_KEYCLOAK_CLIENTID,
};

const REALM = {
  GSE: 'GSE',
  RECOM: 'RECOM',
  MALL: 'MALL',
  HOME: 'MALL', // alias
  VDA: 'VDA',
  SUPPORT: 'SUPPORT',
  TIPS: 'TIPS',
  ASSETS: 'ASSETS',
  ROUTINE: 'ROUTINE',
  // HOME: 'GSE',   // alias
  EXPLORE: 'EXPLORE',
};

if (0 < MALL_KEYCLOAK_IDP.length) configMALL.idpHint = MALL_KEYCLOAK_IDP;
if (0 < GSE_KEYCLOAK_IDP.length) configGSE.idpHint = GSE_KEYCLOAK_IDP;
const REALM_CONFIG = {
  [REALM.RECOM]: {
    config: configRECOM,
    instance: Keycloak(configRECOM),
  },
  [REALM.GSE]: {
    config: configGSE,
    instance: Keycloak(configGSE),
  },
  [REALM.MALL]: {
    config: configMALL,
    instance: Keycloak(configMALL),
  },
};

export const keycloak = {
  realms: REALM_CONFIG,
  realm: REALM,

  debug: 1,
  debugRealmAlert: (severity: number, obj: any) => {
    const limit = 22;
    if (keycloak.debug < 1) {
      // alert('no realm debug');
    } else if (severity >= limit) {
      const ts = (new Date().getTime() % 1e9) / 1e3;
      if (severity <= limit * 2) {
        console.log(ts, obj);
      } else {
        if (severity <= limit * 8) console.error(ts, obj);
        else alert(JSON.stringify([severity, obj]));
      }
    }
  },
};
keycloak.debugRealmAlert(2, 'keycloak.ts reloaded');
