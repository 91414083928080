import {
  SwuifAppConfig,
  AuthProviderProps,
  AuthStorage,
  AuthCredential,
} from 'swuif';
import localforage from 'localforage';
// import appLogo from '@/assets/logo.svg';
// import { APP_NAME, COMPANY_NAME } from './constants';

function createAuthStorage(appName: string): AuthStorage {
  const storage = localforage.createInstance({
    name: appName,
    storeName: 'auth',
    description: `SWUIF Auth Storage for ${appName}`,
  });
  const key = 'credential';
  const skipInitialLoad = false;

  return {
    skipInitialLoad,
    load: () => storage.getItem<AuthCredential | undefined>(key),
    // store is a function that stores the credential to the specified key,
    // if the given credential is null/undefined/other falsy value,
    // then it will perform item removal
    store: credential =>
      credential ? storage.setItem(key, credential) : storage.removeItem(key),
  };
}

const swuifAppConfig: SwuifAppConfig = {
  app: {
    appName: 'Admin Console',
    companyName: 'Samsung',
  },
};

const authStorage: AuthStorage = createAuthStorage('Samsung');

const swuifAuthConfig: Omit<Partial<AuthProviderProps>, 'children'> = {
  storage: authStorage,
};

const swuifPreset = {
  config: swuifAppConfig,
  auth: swuifAuthConfig,
};

export { swuifPreset, authStorage };
