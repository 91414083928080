import { ACTION_TYPE, STATUS_TYPE } from './common';
import {
  ROLE_TYPE as MALL_ROLE_TYPE,
  VDA_ROLE_TYPE,
  ASSETS_ROLE_TYPE,
  EXPLORE_ROLE_TYPE,
} from '@/config/constants_mall';

const {
  ACTIVATE,
  DELETE,
  PAUSE,
  EDIT,
  REJECT,
  REPLICATE,
  SUBMIT,
  COMPLETE,
  APPROVE,
} = ACTION_TYPE;

const {
  ACTIVE,
  ACTIVE_NOT_STARTED,
  ACTIVE_RUNNING,
  DRAFT,
  COMPLETED,
  PENDING,
  PENDING_APPROVAL,
  PAUSED,
  APPROVED,
} = STATUS_TYPE;

export const ROLE_TYPE = {
  RECOMMENDATION_ADMIN: 'RECOMMENDATION_ADMIN',
  RECOMMENDATION_EDITOR: 'RECOMMENDATION_EDITOR',
  RECOMMENDATION_GENERAL: 'RECOMMENDATION_GENERAL',
};

export const ROUTINE_ROLE_TYPE = {
  ROUTINE_ADMIN: 'ROUTINE_ADMIN',
  ROUTINE_EDITOR: 'ROUTINE_EDITOR',
  ROUTINE_GENERAL: 'ROUTINE_GENERAL',
};

export const GSE_ROLE_TYPE = {
  GSE_ADMIN: 'ADMIN',
  GSE_VENDOR: 'VENDOR',
  GSE_READ_ONLY: 'READ_ONLY',
};
export const GSE_ROLE_TYPE_CN = {
  GSE_READ_ONLY: 'READ_ONLY',
};

export const TIPS_ROLE_TYPE = {
  TIPS_MANAGER: 'TIPS_MANAGER',
  TIPS_USER: 'TIPS_USER',
  TIPS_READ_ONLY: 'TIPS_READ_ONLY',
};
export const TIPS_ROLE_TYPE_CN = {
  TIPS_READ_ONLY: 'TIPS_READ_ONLY',
};

export const GSE_LIST_ROLE = {
  GSE_ADMIN: 'admin',
  GSE_VENDOR: 'vendor',
  TIPS_MANAGER: 'tips_manager',
  TIPS_USER: 'tips_user',
  TIPS_READ_ONLY: 'tips_read_only',
  TIPS_SUPER_ADMIN: 'tips_super_admin',
};

export const ROLE_TYPE_DISPLAY = {
  // eslint-disable-next-line
  ['']: '',
  [MALL_ROLE_TYPE.MALL_ACCOUNT_MANAGER]: 'Account Manager',
  [MALL_ROLE_TYPE.MALL_PORTAL_MANAGER]: 'Portal Manager',
  [MALL_ROLE_TYPE.MALL_MARKETER]: 'Marketer',
  [MALL_ROLE_TYPE.MALL_READ_ONLY]: 'Read-only',
  [VDA_ROLE_TYPE.VDA_ADMIN]: 'VDA Admin',
  [VDA_ROLE_TYPE.VDA_MEMBERS]: 'VDA Members',
  [VDA_ROLE_TYPE.VDA_READ_ONLY]: 'VDA Read-only',
  // [MALL_ROLE_TYPE.MALL_ADMIN]: 'Mall Admin',
  [GSE_ROLE_TYPE.GSE_ADMIN]: 'GSE Admin',
  [GSE_ROLE_TYPE.GSE_VENDOR]: 'GSE Vendor',
  [GSE_ROLE_TYPE.GSE_READ_ONLY]: 'GSE Read-only',
  [TIPS_ROLE_TYPE.TIPS_MANAGER]: 'Plugin Manager',
  [TIPS_ROLE_TYPE.TIPS_USER]: 'Plugin User',
  [TIPS_ROLE_TYPE.TIPS_READ_ONLY]: 'Plugin Read-only',
  [ROLE_TYPE.RECOMMENDATION_ADMIN]: 'SR Admin',
  [ROLE_TYPE.RECOMMENDATION_EDITOR]: 'SR Editor',
  [ROLE_TYPE.RECOMMENDATION_GENERAL]: 'SR General',
  [ROUTINE_ROLE_TYPE.ROUTINE_ADMIN]: 'Routine Admin',
  [ROUTINE_ROLE_TYPE.ROUTINE_EDITOR]: 'Routine Editor',
  [ROUTINE_ROLE_TYPE.ROUTINE_GENERAL]: 'Routine General',
  [ASSETS_ROLE_TYPE.ASSETS_MANAGER]: 'Assets Manager',
  [ASSETS_ROLE_TYPE.ASSETS_USER]: 'Assets User',
  [ASSETS_ROLE_TYPE.ASSETS_READ_ONLY]: 'Assets Read-only',
  [EXPLORE_ROLE_TYPE.EXPLORE_MANAGER]: 'Explore Manager',
  [EXPLORE_ROLE_TYPE.EXPLORE_USER]: 'Explore User',
  [EXPLORE_ROLE_TYPE.EXPLORE_READ_ONLY]: 'Explore Read-only',
  // eslint-disable-next-line
  ['SUPERADMIN']: 'Super Admin',
  // eslint-disable-next-line
  ['UPCOMING_FEATURE']: 'Upcoming Feature',
  // eslint-disable-next-line
  ['SUPPORT']: 'Support',
  // eslint-disable-next-line
  ['DID_SETTING']: 'DID Setting',
};

export const ROLE_ACCESS = {
  STATUS_ACTIONS: {
    [ROLE_TYPE.RECOMMENDATION_ADMIN]: {
      [ACTIVE]: [COMPLETE, PAUSE],
      [ACTIVE_RUNNING]: [COMPLETE, PAUSE],
      [ACTIVE_NOT_STARTED]: [COMPLETE, PAUSE],
      [DRAFT]: [EDIT, DELETE, SUBMIT],
      [PENDING]: [REJECT, APPROVE],
      [PENDING_APPROVAL]: [REJECT, APPROVE],
      [COMPLETED]: [REPLICATE],
      [PAUSED]: [EDIT, DELETE, ACTIVATE],
      [APPROVED]: [EDIT, REJECT, ACTIVATE],
    },
    [ROLE_TYPE.RECOMMENDATION_EDITOR]: {
      [ACTIVE]: [],
      [ACTIVE_RUNNING]: [],
      [ACTIVE_NOT_STARTED]: [],
      [DRAFT]: [EDIT, DELETE, SUBMIT],
      [PENDING]: [],
      [PENDING_APPROVAL]: [],
      [COMPLETED]: [REPLICATE],
      [PAUSED]: [],
      [APPROVED]: [],
    },
    [ROLE_TYPE.RECOMMENDATION_GENERAL]: {
      [DRAFT]: [],
      [ACTIVE]: [],
      [ACTIVE_RUNNING]: [],
      [ACTIVE_NOT_STARTED]: [],
      [PENDING]: [],
      [PENDING_APPROVAL]: [],
      [COMPLETED]: [],
      [PAUSED]: [],
      [APPROVED]: [],
    },
    [ROUTINE_ROLE_TYPE.ROUTINE_ADMIN]: {
      [ACTIVE]: [COMPLETE, PAUSE],
      [ACTIVE_RUNNING]: [COMPLETE, PAUSE],
      [ACTIVE_NOT_STARTED]: [COMPLETE, PAUSE],
      [DRAFT]: [EDIT, DELETE, SUBMIT],
      [PENDING]: [REJECT, APPROVE],
      [PENDING_APPROVAL]: [REJECT, APPROVE],
      [COMPLETED]: [REPLICATE],
      [PAUSED]: [EDIT, DELETE, ACTIVATE],
      [APPROVED]: [EDIT, REJECT, ACTIVATE],
    },
    [ROUTINE_ROLE_TYPE.ROUTINE_EDITOR]: {
      [ACTIVE]: [],
      [ACTIVE_RUNNING]: [],
      [ACTIVE_NOT_STARTED]: [],
      [DRAFT]: [EDIT, DELETE, SUBMIT],
      [PENDING]: [],
      [PENDING_APPROVAL]: [],
      [COMPLETED]: [REPLICATE],
      [PAUSED]: [],
      [APPROVED]: [],
    },
    [ROUTINE_ROLE_TYPE.ROUTINE_GENERAL]: {
      [DRAFT]: [],
      [ACTIVE]: [],
      [ACTIVE_RUNNING]: [],
      [ACTIVE_NOT_STARTED]: [],
      [PENDING]: [],
      [PENDING_APPROVAL]: [],
      [COMPLETED]: [],
      [PAUSED]: [],
      [APPROVED]: [],
    },
  },
};

export const ROLE_ACCESS_BULK_TRANSITION = {
  STATUS_ACTIONS: {
    [ROLE_TYPE.RECOMMENDATION_ADMIN]: {
      ALL: [''],
      ACTIVE: [PAUSE, COMPLETE],
      DRAFT: [SUBMIT, DELETE],
      PENDING: [APPROVE, REJECT],
      PENDING_APPROVAL: [APPROVE, REJECT],
      COMPLETED: [REPLICATE],
      PAUSED: [ACTIVATE, EDIT, DELETE],
      APPROVED: [ACTIVATE, REJECT, EDIT],
    },
    [ROLE_TYPE.RECOMMENDATION_EDITOR]: {
      ALL: [],
      ACTIVE: [],
      DRAFT: [SUBMIT, DELETE],
      PENDING: [],
      PENDING_APPROVAL: [],
      COMPLETED: [REPLICATE],
      PAUSED: [],
      APPROVED: [],
    },
    [ROLE_TYPE.RECOMMENDATION_GENERAL]: {
      ALL: [],
      DRAFT: [],
      ACTIVE: [],
      PENDING: [],
      PENDING_APPROVAL: [],
      COMPLETED: [],
      PAUSED: [],
      APPROVED: [],
    },
    [ROUTINE_ROLE_TYPE.ROUTINE_ADMIN]: {
      ALL: [''],
      ACTIVE: [PAUSE, COMPLETE],
      DRAFT: [SUBMIT, DELETE],
      PENDING: [APPROVE, REJECT],
      PENDING_APPROVAL: [APPROVE, REJECT],
      COMPLETED: [REPLICATE],
      PAUSED: [ACTIVATE, EDIT, DELETE],
      APPROVED: [ACTIVATE, REJECT, EDIT],
    },
    [ROUTINE_ROLE_TYPE.ROUTINE_EDITOR]: {
      ALL: [],
      ACTIVE: [],
      DRAFT: [SUBMIT, DELETE],
      PENDING: [],
      PENDING_APPROVAL: [],
      COMPLETED: [REPLICATE],
      PAUSED: [],
      APPROVED: [],
    },
    [ROUTINE_ROLE_TYPE.ROUTINE_GENERAL]: {
      ALL: [],
      DRAFT: [],
      ACTIVE: [],
      PENDING: [],
      PENDING_APPROVAL: [],
      COMPLETED: [],
      PAUSED: [],
      APPROVED: [],
    },
  },
};
