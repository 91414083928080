import axios, { AxiosRequestConfig } from 'axios';
import { keycloak, UNAUTHORIZED_URL } from '@/config';
import { realmHeartBeat } from '@/routes/AuthLogin';

const realmID = keycloak.realm.GSE;
const baseURL = process.env.REACT_APP_GSE_API_URL!;
const isSamsungAPI = (url: string) => {
  return url.startsWith(baseURL);
  // return RegExp(`^${process.env.REACT_APP_GSE_API_URL}`).test(url);
};

const localAxios = axios.create();
localAxios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    realmHeartBeat(realmID, false); // isTokenExpired?
    // keycloak.debugRealmAlert(91, ['Invalid token for', realmID, keycloak.instance]);
    if (error.response && error.response.status === 401) {
      window.location.href = UNAUTHORIZED_URL;
      // window.location.href = '/unauthorized';
    }
    return Promise.reject(error);
  },
);

localAxios.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    let needToken = true;
    if (config.url && config.url.startsWith('http')) {
      needToken = isSamsungAPI(config.url);
      config.baseURL = undefined;
    } else {
      config.baseURL = baseURL;
    }
    // Handle if the request happen when the credential in the storage is empty,
    // then the user will be redirected to keycloak login
    if (needToken) {
      const kc = await realmHeartBeat(realmID);
      const token = kc && kc.token;
      if (!token) {
        // await realmCheckSSO(realmID, true); // can't login during bad network
        return Promise.reject('User Credential not found or expired');
      }
      config.headers.authorization = `Bearer ${token}`;
    } else {
      // keycloak.debugRealmAlert(91, ['interceptors', realmID, config]);
    }
    return Promise.resolve(config);
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

export default {
  get(url: any, config: AxiosRequestConfig = {}) {
    return localAxios({ method: 'GET', url: url, ...config });
  },
  post(url: any, config: AxiosRequestConfig = {}) {
    return localAxios({ method: 'POST', url: url, ...config });
  },
  put(url: any, config: AxiosRequestConfig = {}) {
    return localAxios({ method: 'PUT', url: url, ...config });
  },
  remove(url: any, config: AxiosRequestConfig = {}) {
    return localAxios({ method: 'DELETE', url: url, ...config });
  },
};
