import { domainToKey, keyToDomain } from './constants';

export const extractEmail = (email: string) => {
  const emailArr = email.split('@');
  const userId = emailArr[0];
  const domain = emailArr[1] || '';
  let key = domainToKey[domain] || '';
  return userId + (key && `#${key}`);
};

export const constructEmail = (id: string) => {
  const strArr = id.split('#');
  const userId = strArr[0];
  const key = strArr[1] || '';
  let domain = keyToDomain[key];
  return userId + (domain && `@${domain}`);
};

export const extractId = (email: string) => {
  const emailArr = email.split('@');
  const userId = emailArr[0];
  return userId;
};
