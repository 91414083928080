import React from 'react';
import { authStorage, keycloak, UPDATE_TOKEN_BEFORE } from '@/config';
import { KeycloakInstance } from 'keycloak-js';
import { Row, Button } from 'antd';
import Keycloak from 'keycloak-js';

const storeKey = 'STORE';
const urlHome = window.location.origin;

export const realmLogout = async (doRedirect = true) => {
  await authStorage.store(undefined, false);
  const kcr = keycloak.realm;
  if (localStorage.getItem('users') !== null) {
    localStorage.removeItem('users');
  }

  // if (localStorage.getItem('activeGSEContentColumn') !== null) {
  //   localStorage.removeItem('activeGSEContentColumn');
  // }

  if (localStorage.getItem('activeGSEContentColumnHeroBanner') !== null) {
    localStorage.removeItem('activeGSEContentColumnHeroBanner');
  }

  if (localStorage.getItem('activeGSEContentColumnHowToUse') !== null) {
    localStorage.removeItem('activeGSEContentColumnHowToUse');
  }

  if (localStorage.getItem('activeGSEContentColumnNotifications') !== null) {
    localStorage.removeItem('activeGSEContentColumnNotifications');
  }

  if (localStorage.getItem('activeGSEContentColumnVideos') !== null) {
    localStorage.removeItem('activeGSEContentColumnVideos');
  }

  if (localStorage.getItem('merchandiseFilterValue') !== null) {
    localStorage.removeItem('merchandiseFilterValue');
  }

  if (localStorage.getItem('merchandiseFilterFieldId') !== null) {
    localStorage.removeItem('merchandiseFilterFieldId');
  }

  if (localStorage.getItem('merchandiseFilterValueBrand') !== null) {
    localStorage.removeItem('merchandiseFilterValueBrand');
  }

  if (localStorage.getItem('merchandiseFilterValueCategory') !== null) {
    localStorage.removeItem('merchandiseFilterValueCategory');
  }

  if (localStorage.getItem('lifestyleFilterFieldId') !== null) {
    localStorage.removeItem('lifestyleFilterFieldId');
  }

  if (localStorage.getItem('lifestyleFilterValue') !== null) {
    localStorage.removeItem('lifestyleFilterValue');
  }

  if (localStorage.getItem('activeMerchandiseCountry') !== null) {
    localStorage.removeItem('activeMerchandiseCountry');
  }

  if (localStorage.getItem('activeLifestyleCountry') !== null) {
    localStorage.removeItem('activeLifestyleCountry');
  }

  if (localStorage.getItem('activeStoryCountry') !== null) {
    localStorage.removeItem('activeStoryCountry');
  }

  if (localStorage.getItem('brandFilterFieldId') !== null) {
    localStorage.removeItem('brandFilterFieldId');
  }

  if (localStorage.getItem('brandFilterValue') !== null) {
    localStorage.removeItem('brandFilterValue');
  }

  // localStorage['globalBrandsFilterValue']
  if (localStorage.getItem('globalBrandsFilterValue') != null) {
    localStorage.removeItem('globalBrandsFilterValue');
  }

  if (localStorage.getItem('globalMerchandiseFilterValue') != null) {
    localStorage.removeItem('globalMerchandiseFilterValue');
  }

  if (localStorage.getItem('globalLifestyleFilterValue') != null) {
    localStorage.removeItem('globalLifestyleFilterValue');
  }

  for (const realmID of [kcr.HOME, kcr.GSE, kcr.MALL, kcr.RECOM]) {
    await instanceLogout(realmID);
  }
  // for (const realmID of [kcr.HOME, kcr.GSE, kcr.MALL, kcr.RECOM]) {
  //   localStorage.setItem(storeKey + realmID, 'expired test');
  // }
  // keycloak.debugRealmAlert(91, ['realmLogout++: ', doRedirect]);
  if (doRedirect) window.location.href = urlHome;
};
const realmLogin = async (
  realmID: string,
  loginOption?: Keycloak.KeycloakLoginOptions,
) => {
  const realm = keycloak.realms[realmID];
  const kc = realm.instance;
  const idpHint = realm.config.idpHint;
  keycloak.debugRealmAlert(31, ['realmLogin--: ', realmID, loginOption, realm]);

  // const idpHint = 'keycloak-oidc';
  // TODO multi realm: What if incompatible idpHint/roles used?
  await realmRedirectGuarded(realmID, async () => {
    if (loginOption && 0 < Object.keys(loginOption).length) {
      await kc.login(loginOption);
    } else if (idpHint) {
      await kc.login({ idpHint: idpHint });
    } else {
      await kc.login();
    }
  });
  // keycloak.debugRealmAlert(91, ['realmLogin++: ', realmID, loginOption, realm]);
};
const realmInit = async (
  realmID: string,
  expireSuspect = false,
  doCleanup = true,
  // forcedLogin = false, // KeycloakInitOptions don't have idpHint
) => {
  const kc = realmActivate(realmID);
  if (!kc) {
    return kc; // alert(`unknown ${realmID}`);
  }
  const [initConfig, skc] = instanceInit(realmID);
  const token = initConfig && initConfig.token;
  keycloak.debugRealmAlert(31, ['realmInit', realmID, !!token, ...tskc(kc)]);

  if (kc && kc.authenticated) {
    if (!expireSuspect) {
      expireSuspect = !token; // missing token caused by logout ?
      if (!expireSuspect) {
        expireSuspect = debugRealm.auth_time(skc) !== debugRealm.auth_time(kc);
      }
    }
    const wasEqual = kc.token === token;
    const wasExpired = await realmUpdateToken(kc, expireSuspect ? -1 : 1);
    if (wasExpired) {
      if (!(wasEqual || kc.authenticated)) {
        // alert('will try to load stored token');
      } else {
        instanceStore(realmID, doCleanup ? token : undefined);
        return kc; // refreshed or out of token to load
      }
    } else {
      return kc; // not expired
    }
  }
  if (!token && 'boolean' === typeof kc.authenticated) {
    // alert('avoid endless loop due to init redirect');
  } else {
    await realmRedirectGuarded(realmID, async () => {
      await kc.init(initConfig);
    });
    instanceStore(realmID, doCleanup ? token : null!);
  }
  return kc;
};
const realmUpdateToken = async (kc: KeycloakInstance, scale = 1) => {
  const limit = 0 < scale ? scale * UPDATE_TOKEN_BEFORE * 4 : -1;
  const expired = 0 > limit || kc.isTokenExpired(limit);
  if (expired) {
    keycloak.debugRealmAlert(31, ['isTokenExpired', limit, ...tskc(kc)]);
    try {
      await kc.updateToken(limit);
    } catch (err) {
      keycloak.debugRealmAlert(31, ['realmUpdateToken--', ...tskc(kc)]);
    }
  }
  return expired;
};
const tskc = (kc: KeycloakInstance) => {
  let exp = [kc && kc.tokenParsed && kc.tokenParsed.exp];
  exp = exp.map((ts: any) => (0 < ts ? ts % 1e6 : ts));
  return [...exp, JSON.parse(JSON.stringify(kc))];
};
export const realmCheckSSO = async (
  realmID: string,
  forcedLogin = false,
  loginOption?: Keycloak.KeycloakLoginOptions,
) => {
  let debug = new Date().getTime();
  const kc = await realmInit(realmID);
  if (kc) {
    if (kc.authenticated) {
      // alert('authenticated && updateToken');
    } else if (forcedLogin) {
      await realmLogin(realmID, loginOption);
    }
    debug -= new Date().getTime();
    keycloak.debugRealmAlert(31, ['CheckSSO++', realmID, debug, ...tskc(kc)]);
  }
  return kc;
};
export const realmActivate = (realmID: string) => {
  let kc: KeycloakInstance = null!;
  if (realmID) {
    const realm = keycloak.realms[realmID];
    kc = realm && realm.instance;
  }
  return kc;
};

//

// const workaroundUnkillableSession = (realmID: string) => {
//   const brokenRealms = [keycloak.realm.RECOM];
//   const redirectUriStillBroken = brokenRealms[0] === realmID;
//   if (redirectUriStillBroken) {
//     let [, key] = instanceValue(realmID);
//     localStorage.removeItem(key!); // TODO what if bad network?
//     // alert(key + ' remove cache w/o waiting for session killed confirmation')
//   }
// };
const instanceValue = (realmID: string) => {
  const key = storeKey + realmID;
  return [localStorage.getItem(key), key];
};
const instanceLogout = async (realmID: string) => {
  let val = instanceLoad(realmID);
  if (val) {
    const kc = await realmCheckSSO(realmID);
    if (kc && kc.token) {
      // TODO: intermittent problem that redirectUri isn't executed by kc
      keycloak.debugRealmAlert(31, ['instanceLogout: ', realmID, ...tskc(kc)]);

      // workaroundUnkillableSession(realmID);
      await realmRedirectGuarded(realmID, async () => {
        await kc.logout({ redirectUri: urlHome });
      });
      // a successfull kc.logout will make any stored token invalid
      // a re-login (fresh cookie) can make the token valid again
    } else {
      // don't have token to logout from
      // ... or was invalid (it produce !kc.authenticated)
    }
  }
};
const instanceStore = (realmID: string, tokenStored?: string) => {
  const key = storeKey + realmID;
  const realm = keycloak.realms[realmID];
  if (realm) {
    const kc = realm.instance;
    if (kc && kc.authenticated) {
      if (kc.token === tokenStored) {
        // alert('already saved, no need to replace');
      } else {
        keycloak.debugRealmAlert(31, ['Store++ ', realmID, ...tskc(kc)]);

        let val = JSON.stringify(kc);
        val = Buffer.from(val).toString('base64');
        localStorage.setItem(key, val);
      }
      return;
    }
  }
  if (tokenStored) {
    // remove token/cache since it's invalid (it produce !kc.authenticated)
    keycloak.debugRealmAlert(31, ['Store-- ', realmID, tokenStored, realm]);

    localStorage.removeItem(key);
  }
};
const instanceLoad = (realmID: string) => {
  let [val, key] = instanceValue(realmID);
  if (val) {
    try {
      val = Buffer.from(val, 'base64').toString();
      const kc = JSON.parse(val);
      if (kc && kc.refreshToken && kc.idToken && kc.token && kc.tokenParsed) {
        const exp = kc.tokenParsed.exp || 0; // seconds since epoch
        const delta = new Date().getTime() - exp * 1000;
        const isIdle = delta > 9e7; // expired too long --> flush
        if (!isIdle) return kc;
      }
    } catch (err) {
      // alert(invalid val, err);
    }
  }
  localStorage.removeItem(key!); // unusable val
  return null;
};
export const instanceInit = (realmID: string) => {
  const config: Keycloak.KeycloakInitOptions = {
    // silentCheckSsoRedirectUri: (window.location.origin)+'/needTesting.html',
    // checkLoginIframe: true,
    onLoad: 'check-sso',
    enableLogging: keycloak.debug > 0,
    checkLoginIframe: false, // fix chrome incognito?
  };
  {
    const kc = instanceLoad(realmID);
    if (kc) {
      config.refreshToken = kc.refreshToken;
      config.idToken = kc.idToken;
      config.token = kc.token;
      config.timeSkew = kc.timeSkew; // browser vs server clock
      delete config.onLoad; // avoid/don't redirect if expire
    }
    return [config, kc];
  }
};
const realmRedirectGuarded = async (realmID: string, func: Function) => {
  try {
    sessionStorage.setItem(storeKey, realmID); // realmAcceptCode
    return await func();
  } catch (error) {
    // console.error('realmRedirectGuarded: ', error); // invalid token?
  } finally {
    sessionStorage.removeItem(storeKey);
  }
};
export const realmAcceptCode = async () => {
  let realmID = sessionStorage.getItem(storeKey); // realmRedirectGuarded
  sessionStorage.removeItem(storeKey);
  const href = window.location.href;
  if (realmID) {
    debugRealm.realmID = realmID;
    let tmp;
    const urlHasKCcode =
      (0 < (tmp = href.indexOf('?')) && tmp < href.indexOf('state=', tmp)) ||
      (0 < (tmp = href.indexOf('#')) && tmp < href.indexOf('state=', tmp));
    if (urlHasKCcode) {
      keycloak.debugRealmAlert(31, ['realmAcceptCode ', realmID, href]);

      const forcedLogin =
        realmID !== keycloak.realm.HOME &&
        !!instanceValue(keycloak.realm.HOME)[0] && // isLoginCycle?
        keycloak.debug < 2;
      await realmCheckSSO(realmID, forcedLogin);
    }
  }
};

export const realmHeartBeat = async (realmID: string, canSkip = true) =>
  await HB.checkExpire(realmID, canSkip);
const HB = {
  nextTS: 0,
  debugID: 0,
  promised: null as any,
  checkExpire: async (realmID: string, canSkip = true, debugID = -1) => {
    if (0 > debugID) debugID = HB.debugID++; // to track states of parallel instances
    if ('ADMIN_CONSOLE' === realmID) realmID = keycloak.realm.HOME;
    let ts = new Date().getTime() / 1000;
    const tooSoon = ts < HB.nextTS && canSkip;
    if (tooSoon) {
      // alert('prevent heart attack :p');
    } else {
      HB.nextTS = ts + UPDATE_TOKEN_BEFORE; // prevent heart attack
      const promised = HB.promised;
      if (!promised) {
        // keycloak.debugRealmAlert(131, ['Promise new', debugID]);

        HB.promised = new Promise<void>(async function(myResolve) {
          try {
            await Promise.all(
              (await HB.actives()).map(async realmID => {
                await HB.checkKC(await realmCheckSSO(realmID), realmID);
              }),
            );
          } finally {
            myResolve();
          }
        });
        await HB.promised;
        HB.promised = null; // Promise already completed
      } else {
        // keycloak.debugRealmAlert(131, ['Promise await', debugID]);
        await promised;
      }
    }

    let kc = realmActivate(realmID);
    // ts -= new Date().getTime();
    // const tmp = ['realmHeartBeat', realmID, debugID, HB.nextTS, ts, ...tskc(kc),];
    // keycloak.debugRealmAlert(131, tmp);

    if (kc) {
      try {
        if (kc.tokenParsed && !kc.isTokenExpired(UPDATE_TOKEN_BEFORE * 3))
          return kc; // hepi ending
      } catch (error) {}

      kc = null!;
      HB.nextTS = -1; // critical! Need close monitoring
      if (tooSoon) {
        // do emergency/forced re-checkExpire
        kc = (await HB.checkExpire(realmID, false, debugID))!;
      }
    }
    return kc;
  },
  checkKC: async (kc: KeycloakInstance, realmID?: string) => {
    if (!kc) {
      // alert(`unknown ${realmID}`);
    } else if (!kc.token) {
      // HB.nextTS = -1; // critical! Need close monitoring
      // window.location.reload(true);
      await realmLogout();
    } else if (realmID) HB.checkAsync(kc, realmID);
  },
  checkAsync: (kc: KeycloakInstance, realmID: string) => {
    // do backgorund update 2x duration ahead to optimize future checkExpire
    const token = kc.token;
    realmUpdateToken(kc, 3).then(wasExpired => {
      if (wasExpired) {
        HB.checkKC(kc).then(_ => {
          instanceStore(realmID, token);
          HB.nextTS = -1; // critical! need to verify network quality
        });
      }
      // keycloak.debugRealmAlert(31, ['checkKC', realmID, wasExpired, ...tskc(kc)]);
    });
  },
  actives: async () => {
    const creds = await authStorage.load();
    const roles = (creds && creds.user && creds.user.roles) || {};
    if (0 < Object.keys(roles).length) roles[keycloak.realm.HOME] = 'mandatory';
    return HB.realms.filter(id => roles[id]);
  },
  realms: [keycloak.realm.MALL, keycloak.realm.GSE, keycloak.realm.RECOM],
};
//

const login = async () => {
  /*/
  const forcedLogin = true;
  const realmID = keycloak.realm.MALL;
  const kc = await realmCheckSSO(realmID, forcedLogin);
  updateUserInfo(realmID, kc.tokenParsed);
};
const getRoleInfo = (userInfo: Omit<Partial<KeycloakUserInfo>, 'children'>) => {
  const { roles }: KeycloakRoles = userInfo.realm_access || { roles: [''] };
  console.log('[keycloak.init] roles=', roles);

  return roles;
};
const updateUserInfo = (
  realmID: string,
  userInfo: Omit<Partial<KeycloakUserInfo>, 'children'> | undefined,
) => {
  {
    {
      if (userInfo) {
        console.log('[keycloak.init] userInfo=', userInfo);
        let userRole = getRoleInfo(userInfo);
        console.log('[keycloak.init] userRole=', userRole);
        console.log(
          '[keycloak.init] resourceAccess=',
          userInfo.resource_access,
        );
        let userCredential: AuthCredential = {
          key: userInfo.preferred_username || '',
          user: {
            role: '',
            userId: userInfo.email || '',
            fullName: userInfo.preferred_username || '',
            // keycloakRole: userRole.roleType,
            realms: userInfo,
            userRole: userRole,
          },
        };

        authStorage.store(userCredential, true).then(async _ => {
          if (window.location.pathname === '/authLogin') {
            await realmRedirectGuarded(realmID, async () => {
              await window.location.replace('/');
            });
          }
        });
      }
    }
  }
  //*/
};

//

Keycloak.prototype.onAuthLogout = (obj: any) => {
  keycloak.debugRealmAlert(31, ['onAuthLogout', obj]);
};
Keycloak.prototype.onAuthError = (obj: any) => {
  keycloak.debugRealmAlert(31, ['onAuthError', obj]);
};
Keycloak.prototype.onAuthRefreshError = (obj: any) => {
  keycloak.debugRealmAlert(31, ['onAuthRefreshError', obj]);
};

const debugRealm = {
  realmID: keycloak.realm.MALL,
  parseExpire: (kc: KeycloakInstance) => {
    let ts = (kc && kc.tokenParsed && kc.tokenParsed.exp)!;
    if (!ts && kc && typeof kc.authenticated === 'boolean') ts = -1;
    return 0 < ts ? ts % 1e6 : ts;
  },
  auth_time: (kc: KeycloakInstance) => {
    return (kc.tokenParsed || {})['auth_time'];
  },
  compare: (kc0: KeycloakInstance, kc1: KeycloakInstance) => {
    return [
      kc0.idToken === kc1.idToken ? 1 : 0,
      kc0.refreshToken === kc1.refreshToken ? 1 : 0,
      kc0.token === kc1.token ? 1 : 0,
      debugRealm.auth_time(kc0) === debugRealm.auth_time(kc1) ? 1 : 0,
    ];
  },
  show: (title = '') => {
    const local = instanceLoad(debugRealm.realmID);
    const kc = realmActivate(debugRealm.realmID);
    keycloak.debugRealmAlert(31, [
      title,
      debugRealm.realmID,
      ...debugRealm.compare(kc || {}, local || {}),
      Math.ceil(new Date().getTime() / 1000) % 1e6,
      ...[kc, local].map(kc => debugRealm.parseExpire(kc)),
      kc,
      local,
    ]);
  },

  rapidLast: 0,
  rapidBlocked: () => {
    const delta = new Date().getTime() - debugRealm.rapidLast;
    debugRealm.rapidLast += delta;
    return delta < 999;
  },
  doGSE: () => {
    debugRealm.realmID = keycloak.realm.GSE;
    debugRealm.show('compare');
  },
  doMALL: () => {
    debugRealm.realmID = keycloak.realm.MALL;
    debugRealm.show('compare');
  },
  doRECOM: () => {
    debugRealm.realmID = keycloak.realm.RECOM;
    debugRealm.show('compare');
  },

  save: () => {
    if (debugRealm.rapidBlocked()) return;
    instanceStore(debugRealm.realmID);
    const realm = keycloak.realms[debugRealm.realmID];
    realm.instance = Keycloak(realm.config); // flush
    debugRealm.show('saved');
  },
  load: async () => {
    if (debugRealm.rapidBlocked()) return;
    await realmCheckSSO(debugRealm.realmID, false);
    // realmHeartBeat(debugRealm.realmID);
    // await realmInit(debugRealm.realmID, false, false);
  },
  login: async () => {
    if (debugRealm.rapidBlocked()) return;
    let realmID = debugRealm.realmID || keycloak.realm.HOME || '';
    const kc = await realmCheckSSO(realmID, true);
    debugRealm.show('login');
    if (kc.token) {
      // realmID = keycloak.realm.HOME || debugRealm.realmID || '';
      // realmID = keycloak.realms[realmID].config.realm;
      // const kca = kcMulti[realmID];
      // const accesToken: string = await kca.loginWithToken(kc.token, realmID);
      // let tmp = ['secret', kca, kca.clientCode, accesToken.substr(0, 11)];
      // keycloak.debugRealmAlert(31, tmp);
    }
  },
  update: () => {
    if (debugRealm.rapidBlocked()) return;
    const kc = realmActivate(debugRealm.realmID);
    realmUpdateToken(kc, -1);
  },
  logout: async () => {
    if (debugRealm.rapidBlocked()) return;
    const kc = realmActivate(debugRealm.realmID);
    debugRealm.show(`logout ${urlHome}`);
    // instanceLogout(debugRealm.realmID);
    await realmRedirectGuarded(debugRealm.realmID, async () => {
      await kc.logout({ redirectUri: urlHome });
    });
  },
};

const AuthLogin = () => {
  if (debugRealm) {
    debugRealm.show('restart');
    return (
      <>
        <Row>
          <Button onClick={debugRealm.doRECOM}>RECOM</Button>
          <Button onClick={debugRealm.doMALL}>MALL</Button>
          <Button onClick={debugRealm.doGSE}>GSE</Button>
        </Row>
        <Row>
          <Button onClick={debugRealm.save}>save</Button>
          <Button onClick={debugRealm.load}>load</Button>
          <Button onClick={debugRealm.login}>login</Button>
          <Button onClick={debugRealm.update}>update</Button>
          <Button onClick={debugRealm.logout}>logout</Button>
        </Row>
      </>
    );
  }
  login();
  return <div>Authenticating...</div>;
};

export default AuthLogin;
