import Joi from 'joi';
import {
  generateUploadUrl,
  uploadWithGeneratedUrl,
} from '@/routes/Main/Gse/actions/bulkUploadActions';
import { useAuth } from 'swuif';
import { keycloak } from '@/config/keycloak';
import { KeycloakTokenParsed } from 'keycloak-js';
function _validateImage(imageObj: any, validateSchema: any) {
  const validateResult = Joi.validate(imageObj, validateSchema, {
    abortEarly: false,
    allowUnknown: true,
  });

  const returnResponse = { error: false, errors: [] };
  if (validateResult.error) {
    returnResponse.error = true;
    returnResponse.errors = validateResult.error.details;
  }

  return returnResponse;
}

export function uploadImages(rawFileList: any, validationSchema: any) {
  return new Promise(async resolve => {
    const fileArray: any = [];
    const fileErrors = [];
    let fileList: any;
    if (rawFileList.hasOwnProperty('fileList'))
      fileList = Array.from(rawFileList.fileList);
    else fileList = Array.from(rawFileList);

    const fileListLength = fileList.length;
    for (let index = 0; index < fileListLength; index++) {
      const file = fileList[index];

      //Validate img
      const imgObj = {
        name: file.name,
        size: file.size,
      };

      const validateResults: any = _validateImage(imgObj, validationSchema);

      if (!validateResults.error) {
        fileArray.push({
          key: file.name,
          type: file.type,
        });
      } else {
        fileArray.push(null);
        const error = { ...imgObj, ...validateResults.errors[0] };
        fileErrors.push(error);
      }
    }

    const uploadUrls: any = await generateUploadUrl(fileArray);
    const promiseArray: any = [];

    uploadUrls.forEach((uploadObj: any, index: any) => {
      if (uploadObj) {
        promiseArray.push(
          uploadWithGeneratedUrl(
            uploadObj.url,
            fileList[index].originFileObj,
            fileList[index].type,
          ),
        );
      }
    });
    const uploadResults = await Promise.all(promiseArray);

    const returnResponse = {};
    uploadResults.forEach((response, responseIndex) => {
      returnResponse[fileArray[responseIndex].key] = {
        key: uploadUrls[responseIndex].key,
        response: response,
      };
    });

    resolve({ response: returnResponse, errors: fileErrors });
  });
}
export function toBase64(file: any) {
  return new Promise((resolve, reject) => {
    file = file.originFileObj;
    if (file instanceof Blob === false) {
      resolve('');
    }
    try {
      const reader = new FileReader();
      reader.onload = () => {
        var b64File = reader.result;
        resolve(b64File);
      };
      reader.readAsDataURL(file);
    } catch (err) {
      reject(err);
    }
  });
}

export interface KeycloakUserInfo extends KeycloakTokenParsed {
  preferred_username: string;
  name: string;
  email: string;
  vendor: string;
  country: string[];
}
const parseCountry = (userInfo: Partial<KeycloakUserInfo>) => {
  // console.log(['parseCountry: ', userInfo]);
  const userCountries =
    userInfo.country && userInfo.country[0] ? userInfo.country : [''];
  return userCountries[0]
    .slice(1, userCountries[0].length - 1) // "[all]"
    .split(',')
    .map(countryCode => countryCode.trim())
    .filter(countryCode => countryCode !== '');
};
const keyGseCountries = keycloak.realm.GSE + '-countries';
const keyGseVendor = keycloak.realm.GSE + '-vendor';

export function parseGSEtoken(
  userRole: {},
  userInfo: Partial<KeycloakUserInfo>,
) {
  let tmp;
  // if (!!(tmp = userInfo['division'])) userRole[keyGseDivision] = tmp;
  if (!!(tmp = userInfo.vendor)) userRole[keyGseVendor] = tmp;
  userRole[keyGseCountries] = parseCountry(userInfo);
}

export function getAuthGSE() {
  // eslint-disable-next-line
  const [{ credential }] = useAuth();
  return {
    userRoles: credential!.user.roles[keycloak.realm.GSE],
    userCountries: credential!.user.roles[keyGseCountries],
    userVendor: credential!.user.roles[keyGseVendor],
  };
}
