import KeycloakAdminClient from 'keycloak-admin';
import { ConnectionConfig } from 'keycloak-admin/lib/client';
// import { Realms } from 'keycloak-admin/lib/resources/realms';
// import RealmRepresentation from 'keycloak-admin/lib/defs/realmRepresentation';
import UserRepresentation from 'keycloak-admin/lib/defs/userRepresentation';
import RoleRepresentation, {
  RoleMappingPayload,
} from 'keycloak-admin/lib/defs/roleRepresentation';
import {
  MALL_KEYCLOAK_HOST,
  GSE_ROLE_TYPE,
  ROLE_TYPE,
  RECOM_KEYCLOAK_HOST,
  GSE_KEYCLOAK_HOST,
  SECRET_SERVER_URL,
  TIPS_ROLE_TYPE,
  MALL_KEYCLOAK_REALM,
  RECOM_KEYCLOAK_REALM,
  GSE_KEYCLOAK_REALM,
  ROUTINE_ROLE_TYPE,
  TIPS_ROLE_TYPE_CN,
  GSE_ROLE_TYPE_CN,
} from './constants';
import axios, { AxiosRequestConfig } from 'axios';
import {
  ROLE_TYPE as MALL_ROLE_TYPE,
  VDA_ROLE_TYPE,
  ASSETS_ROLE_TYPE,
  EXPLORE_ROLE_TYPE,
} from '@/config/constants_mall';
import FederatedIdentityRepresentation from 'keycloak-admin/lib/defs/federatedIdentityRepresentation';
import { UserQuery } from 'keycloak-admin/lib/resources/users';
import { Dictionary } from 'swuif';

// class asd extends ConnectionConfig {
const TIMEOUT = 10000; //millisecond
let source = axios.CancelToken.source();
setTimeout(() => {
  source.cancel();
  // Timeout Logic
}, TIMEOUT);
// }
// const TEST = true;
const userMgmt = axios.create();
const request = {
  put(url: any, config: AxiosRequestConfig = {}) {
    config.timeout = TIMEOUT;
    return userMgmt({ method: 'PUT', url: url, ...config });
  },
  post(url: any, config: AxiosRequestConfig = {}) {
    config.timeout = TIMEOUT;
    return userMgmt({ method: 'POST', url: url, ...config });
  },
};

export class KeycloakRequirement {
  config: ConnectionConfig;
  constructor(url: string, realmName: string) {
    this.config = {
      baseUrl: url,
      realmName: realmName,
    };
  }
}

class KeycloakAdmin {
  kcAdmin: KeycloakAdminClient;
  clientCode: string = '';
  constructor(config: KeycloakRequirement) {
    this.kcAdmin = new KeycloakAdminClient(config.config);
  }

  async login(token: string) {
    let result = false;
    let accesToken = '';
    try {
      if (token.length > 0) {
        accesToken = await this.loginWithToken(token, this.kcAdmin.realmName);
      }
    } catch (err) {
      const errs: Error = err as Error;
      if (errs.message.startsWith('timeout of')) {
        throw new Error('Server was Busy');
      }
    }
    if (accesToken.length > 0) {
      result = true;
      this.kcAdmin.setAccessToken(accesToken);
    }
    return result;
  }

  async loginWithToken(token: string, realmName: string) {
    console.log('aaa realmName : ', realmName);
    const result = await request.post(SECRET_SERVER_URL + '/login', {
      data: { token: token, realm: realmName },
    });
    const data = result.data;
    // console.log(data);
    const accessToken = data['token'];
    this.clientCode = data['code'];
    return accessToken;
  }
  async getRegisterWithToken(token: string, realmName: string) {
    const result = await request.post(SECRET_SERVER_URL + '/register', {
      data: { token: token, realm: realmName },
    });
    const data = result.data;
    return data;
  }

  async editRegisterWithToken(
    token: string,
    user: UserRepresentation,
    realmName: string,
  ) {
    const result = await request.put(SECRET_SERVER_URL + '/register', {
      data: { token: token, user: user, realm: realmName },
    });
    const data = result.data;
    return data;
  }

  async getRealms(realmName = '') {
    /*
    return list of realms or a single realm depend on realmName parameter
    */
    if (realmName.length === 0) {
      const result = await this.kcAdmin.realms.find();
      // console.log(result);
      return result;
    } else {
      const result = await this.kcAdmin.realms.findOne({ realm: realmName });
      return result;
    }
  }

  async getRoles(realmName = 'master') {
    /*
    get Roles on specific realm
    */

    // if (prevRealm != realmName) this.kcAdmin.realmName = realmName;
    this.kcAdmin.realmName = realmName;
    const result = await this.kcAdmin.roles.find();

    return result;
  }

  buildRole(roleName: string, description: string = '') {
    /*
    build Roles object
    */
    const result: RoleRepresentation = {
      name: roleName,
      description: description,
    };
    return result;
  }

  async createRole(role: RoleRepresentation, realmName = 'master') {
    /*
    create Role on specific realm
    */

    // if (prevRealm != realmName) this.kcAdmin.realmName = realmName;
    this.kcAdmin.realmName = realmName;
    const result = await this.kcAdmin.roles.create(role);

    return result;
  }

  async editRole(name: string, role: RoleRepresentation, realmName = 'master') {
    /*
    create Role on specific realm
    */

    // if (prevRealm != realmName) this.kcAdmin.realmName = realmName;
    this.kcAdmin.realmName = realmName;
    const result = await this.kcAdmin.roles.updateByName({ name: name }, role);

    return result;
  }

  async getNumUsers(realmName = 'master', userQuery: UserQuery) {
    /*
    get list of Users on specific realm
    */ // if (prevRealm != realmName) this.kcAdmin.realmName = realmName;
    this.kcAdmin.realmName = realmName;
    userQuery.first = undefined;
    userQuery.max = 999;
    const result = await this.kcAdmin.users.find(userQuery);
    // console.log('all user');
    // console.log(result);
    return result.length;
  }

  async getUsers(realmName = 'master', userQuery: UserQuery) {
    /*
    get list of Users on specific realm
    */
    if (userQuery.max === undefined) {
      userQuery.max = 999;
    }
    // if (prevRealm != realmName) this.kcAdmin.realmName = realmName;
    this.kcAdmin.realmName = realmName;
    const result = await this.kcAdmin.users.find(userQuery);
    // console.log('all user');
    // console.log(result);
    // console.log(await this.kcAdmin.roles.findUsersWithRole({ name: 'ADMIN' }));
    return result;
  }

  async getUser(
    id: string,
    realmName = 'master',
    FindOne?: UserRepresentation,
  ) {
    /*
    get User detail on specific realm
    */

    // if (prevRealm != realmName) this.kcAdmin.realmName = realmName;
    this.kcAdmin.realmName = realmName;
    // get user info *NB: still bug(incomplete detail)
    let result: UserRepresentation;
    if (FindOne) {
      result = FindOne;
    } else {
      result = await this.kcAdmin.users.findOne({ id: id });
    }
    // get user roles (quick fix)
    let roles: RoleRepresentation[] = [];
    if (this.clientCode === '') {
      roles = await this.kcAdmin.users.listRealmRoleMappings({ id: id });
    } else {
      roles = await this.kcAdmin.users.listClientRoleMappings({
        id: id,
        clientUniqueId: this.clientCode,
      });
    }
    result.realmRoles = roles.map(x => x.name as string);
    // console.log(result);
    return result;
  }

  async getUserIdByEmail(email: string, realmName = 'master') {
    /*
    get User detail on specific realm
    */

    // if (prevRealm != realmName) this.kcAdmin.realmName = realmName;
    this.kcAdmin.realmName = realmName;
    // get user info *NB: still bug(incomplete detail)
    let result = await this.kcAdmin.users.find({
      email: email,
      realm: realmName,
    });
    // console.log(result);
    // get user roles (quick fix)
    let result_id: string = '';
    if (result.length === 1 && result[0].email === email) {
      const bestResult = result[0];
      // console.log('key', email, 'result:', bestResult.email);
      // console.log(bestResult);
      result_id = bestResult.id!;
    }

    return result_id;
  }

  async getUsersDetail(
    realmName = 'master',
    samsungFilter = true,
    userQuery?: UserQuery,
    findByRoles: string[] = [],
  ) {
    /*
    get list of Users on specific realm
    */

    // if (prevRealm != realmName) this.kcAdmin.realmName = realmName;
    this.kcAdmin.realmName = realmName;
    let result = await this.getUsers(realmName, userQuery ? userQuery : {});
    if (samsungFilter) {
      result = result.filter(
        (value: UserRepresentation) =>
          value.email && value.email.endsWith('samsung.com'),
      );
    }
    let resultDetail: UserRepresentation[] = [];
    if (findByRoles.length === 0) {
      resultDetail = await Promise.all(
        result.map(x => this.getUser(x.id as string, realmName, x)),
      );
    } else {
      let finalUser: Dictionary<UserRepresentation> = {};
      // console.log(findByRoles);
      for (let i = 0; i < result.length; i++) {
        const currUser = result[i];
        finalUser[currUser.id!] = currUser;
      }
      for (let i = 0; i < findByRoles.length; i++) {
        const currRole = findByRoles[i];
        if (currRole.length === 0) {
          continue;
        }
        let foundUsers = await this.kcAdmin.roles.findUsersWithRole({
          name: currRole,
          realm: realmName,
          max: 999,
        });
        for (let j = 0; j < foundUsers.length; j++) {
          const currUser = foundUsers[j];
          let tempRoles = finalUser[currUser.id!].realmRoles!;
          if (tempRoles) {
            tempRoles.push(currRole);
          } else {
            tempRoles = [currRole];
          }
          finalUser[currUser.id!].realmRoles = tempRoles;
        }
      }
      Object.keys(finalUser).forEach((id: string) => {
        resultDetail.push(finalUser[id]);
      });
    }

    // console.log(resultDetail);
    return resultDetail!;
  }
  buildUser(username: string, email: string, roles: string[]) {
    /*
    create user object
    */
    const result: UserRepresentation = {
      email: email,
      username: username,
      realmRoles: roles,
    };
    return result;
  }

  async createUser(
    newUser: UserRepresentation,
    newRole: RoleRepresentation | undefined,
    realmName = 'master',
  ) {
    /*
    create User on specific realm
    */

    // if (prevRealm != realmName) this.kcAdmin.realmName = realmName;
    this.kcAdmin.realmName = realmName;
    const resultUser = await this.kcAdmin.users.create(newUser);
    let resultRoles = null;
    if (newRole) {
      resultRoles = Promise.resolve(
        this.addRoleMap(resultUser.id, newRole, realmName),
      );
    }

    const result = { user: resultUser, roles: resultRoles };
    // console.log(result);
    return result;
  }

  async deleteUser(id: string, realmName = 'master') {
    /*
    delete User on specific realm
    */

    // if (prevRealm != realmName) this.kcAdmin.realmName = realmName;
    this.kcAdmin.realmName = realmName;
    const result = await this.kcAdmin.users.del({ id: id });

    return result;
  }

  async editUser(
    id: string,
    newUser: UserRepresentation,
    realmName = 'master',
    originalRole?: RoleRepresentation,
    newRole?: RoleRepresentation,
  ) {
    /*
    create User on specific realm
    */

    // if (prevRealm != realmName) this.kcAdmin.realmName = realmName;
    this.kcAdmin.realmName = realmName;
    const result = await this.kcAdmin.users.update({ id: id }, newUser);
    if (originalRole === newRole) {
    } else {
      this.editRoleMap(id, originalRole!, newRole!, realmName);
    }

    return result;
  }

  async logout(id: string, realmName = 'master') {
    this.kcAdmin.realmName = realmName;
    const result = await this.kcAdmin.users.logout({
      id: id,
      realm: realmName,
    });
    return result;
  }

  buildRoleMap(original: string, newR: string, roles: RoleRepresentation[]) {
    let originalRole: RoleRepresentation = { id: '' };
    let newRole: RoleRepresentation = { id: '' };

    for (let i = 0; i < roles.length; i++) {
      if (roles[i].name!.toLowerCase() === original.toLowerCase()) {
        originalRole = roles[i];
      }
      if (roles[i].name!.toLowerCase() === newR.toLowerCase()) {
        newRole = roles[i];
      }
    }
    const result = { original: originalRole, new: newRole };
    // console.log(result);
    return result;
  }

  roleMapFind(keys: string, roles: RoleRepresentation[]) {
    let newRole: RoleRepresentation = { id: '' };
    for (let i = 0; i < roles.length; i++) {
      if (roles[i].name === keys) {
        newRole = roles[i];
      }
    }
    return newRole;
  }

  async editRoleMap(
    id: string,
    originalRole: RoleRepresentation,
    newRole: RoleRepresentation,
    realmName: string,
    clearRole: RoleRepresentation[] = [],
  ) {
    this.kcAdmin.realmName = realmName;
    if (originalRole.id === newRole.id && clearRole.length === 0) {
      return true;
    }
    clearRole = clearRole.filter(
      (v: RoleRepresentation) =>
        ['uma_authorization', 'offline_access'].indexOf(v.name!.toLowerCase()) <
        0,
    );
    let canAdd = true;
    try {
      if (clearRole.length > 0) {
        canAdd = await this.deleteRoleMap(id, clearRole, realmName);
        if (canAdd === false) {
        }
      } else if (originalRole.id!.length > 0) {
        canAdd = await this.deleteRoleMap(id, [originalRole], realmName);
        if (canAdd === false) {
        }
      }
    } catch {
      console.log('error delete realmRole', realmName);
    }
    try {
      if (newRole.id!.length > 0 && canAdd) {
        canAdd = await this.addRoleMap(id, newRole, realmName);
        if (canAdd === false) {
        }
      }
    } catch {
      console.log('error add realmRole', realmName);
    }

    // console.log(result);
    return canAdd;
  }

  async addRoleMap(id: string, newRole: RoleRepresentation, realmName: string) {
    this.kcAdmin.realmName = realmName;

    const newRoles: RoleMappingPayload[] = [
      { id: newRole.id!, name: newRole.name! },
    ];
    const result = await this.kcAdmin.users
      .addRealmRoleMappings({
        id: id,
        roles: newRoles,
      })
      .then(() => {
        return true;
      })
      .catch((reason: any) => {
        console.log(reason);
        return false;
      });

    // console.log('addRoleMap', result);
    return result;
  }

  async deleteRoleMap(
    id: string,
    deletedRole: RoleRepresentation[],
    realmName: string,
  ) {
    this.kcAdmin.realmName = realmName;

    // const newRoles: RoleMappingPayload[] = [
    //   { id: deletedRole.id!, name: deletedRole.name! },
    // ];
    const result = await this.kcAdmin.users
      .delRealmRoleMappings({
        id: id,
        roles: deletedRole as RoleMappingPayload[],
      })
      .then(() => {
        return true;
      })
      .catch((reason: any) => {
        console.log(reason);
        return false;
      });

    return result;
  }

  //unused (unauthorized error)
  async getClientID(realmName: string, clientName: string) {
    this.kcAdmin.realmName = realmName;
    const result = await this.kcAdmin.clients.find({ viewableOnly: true });
    let clientId = '';
    for (let i = 0; i < result.length; i++) {
      if (result[i].clientId === clientName) {
        clientId = result[i].id!;
        break;
      }
    }
    return clientId;
  }

  async getClientRoles(realmName: string) {
    let result: RoleRepresentation[] = [];
    if (this.clientCode === '') {
      return result;
    }
    this.kcAdmin.realmName = realmName;
    result = await this.kcAdmin.clients.listRoles({ id: this.clientCode });
    return result;
  }

  async getClientRoleMap(id: string, realmName: string) {
    let result: RoleRepresentation[] = [];
    if (this.clientCode === '') {
      return result;
    }
    this.kcAdmin.realmName = realmName;
    const result1 = await this.kcAdmin.users.listClientRoleMappings({
      id: id,
      clientUniqueId: this.clientCode,
    });
    const result2 = await this.kcAdmin.users.listAvailableClientRoleMappings({
      id: id,
      clientUniqueId: this.clientCode,
    });
    result.push(...result1);
    result.push(...result2);
    return result;
  }

  async addClientRoleMap(
    id: string,
    newRole: RoleRepresentation,
    realmName: string,
  ) {
    this.kcAdmin.realmName = realmName;

    const newRoles: RoleMappingPayload[] = [
      { id: newRole.id!, name: newRole.name! },
    ];
    const result = await this.kcAdmin.users
      .addClientRoleMappings({
        id: id,
        clientUniqueId: this.clientCode,
        roles: newRoles,
      })
      .then(() => {
        return true;
      })
      .catch((reason: any) => {
        console.log(reason);
        return false;
      });

    // console.log('addRoleMap', result);
    return result;
  }

  async deleteClientRoleMap(
    id: string,
    deletedRole: RoleRepresentation[],
    realmName: string,
  ) {
    this.kcAdmin.realmName = realmName;

    // const newRoles: RoleMappingPayload[] = [
    //   { id: deletedRole.id!, name: deletedRole.name! },
    // ];
    const result = await this.kcAdmin.users
      .delClientRoleMappings({
        id: id,
        clientUniqueId: this.clientCode,
        roles: deletedRole as RoleMappingPayload[],
      })
      .then(() => {
        return true;
      })
      .catch((reason: any) => {
        console.log(reason);
        return false;
      });

    return result;
  }

  async changeClientRole(
    id: string,
    oldRole: RoleRepresentation,
    newRole: RoleRepresentation,
    realmName: string,
    clearRole: RoleRepresentation[] = [],
  ) {
    this.kcAdmin.realmName = realmName;
    if (oldRole.id === newRole.id && clearRole.length === 0) {
      return true;
    }
    clearRole = clearRole.filter(
      (v: RoleRepresentation) =>
        ['uma_authorization', 'offline_access'].indexOf(v.name!.toLowerCase()) <
        0,
    );
    let canAdd = true;
    try {
      if (clearRole.length > 0) {
        canAdd = await this.deleteClientRoleMap(id, clearRole, realmName);
        if (canAdd === false) {
        }
      } else if (oldRole.id!.length > 0) {
        canAdd = await this.deleteClientRoleMap(id, [oldRole], realmName);
        if (canAdd === false) {
        }
      }
    } catch {
      console.log('error delete clientRole', realmName);
    }

    try {
      if (newRole.id!.length > 0 && canAdd) {
        canAdd = await this.addClientRoleMap(id, newRole, realmName);
        if (canAdd === false) {
        }
      }
    } catch {
      console.log('error add clientRole', realmName);
    }
    return canAdd;
  }

  async getUserIDP(id: string, realmName: string) {
    this.kcAdmin.realmName = realmName;
    const result = await this.kcAdmin.users.listFederatedIdentities({ id: id });
    return result;
  }

  createUserIDP(
    id: string,
    idps: FederatedIdentityRepresentation[],
    realmName: string,
  ) {
    this.kcAdmin.realmName = realmName;
    idps.forEach(async (value: FederatedIdentityRepresentation) => {
      await this.kcAdmin.users.addToFederatedIdentity({
        id: id,
        federatedIdentityId: value.identityProvider!,
        federatedIdentity: value,
      });
    });
    return;
  }

  async createUpdateUserAttr(id: string, attr: {}, realmName: string) {
    this.kcAdmin.realmName = realmName;
    const result = this.kcAdmin.users.update({ id: id }, { attributes: attr });
    return result;
  }
}

export var defaultConfigMall: KeycloakRequirement = new KeycloakRequirement(
  MALL_KEYCLOAK_HOST,
  MALL_KEYCLOAK_REALM,
);
export var defaultConfigRecom: KeycloakRequirement = new KeycloakRequirement(
  RECOM_KEYCLOAK_HOST,
  RECOM_KEYCLOAK_REALM,
);
export var defaultConfigGSE: KeycloakRequirement = new KeycloakRequirement(
  GSE_KEYCLOAK_HOST,
  GSE_KEYCLOAK_REALM,
);

//list of all keycloak
export const KEYCLOAK_LOCATION = {
  GSE: 'gse',
  'recommendation-portal': 'recommendation-portal',
  IOTAdminConsole: 'content-portal',
  IOTAdminConsoleVda: 'vda',
  TIPS: 'plugin',
  IOTAdminConsoleAssets: 'assets',
  ROUTINE: 'routine',
  IOTAdminConsoleExplore: 'explore',
};

// keycloak object
var kcGSE = new KeycloakAdmin(defaultConfigGSE);
var kcRecomm = new KeycloakAdmin(defaultConfigRecom);
var kcContent = new KeycloakAdmin(defaultConfigMall);

// mapping of keycloak object
export var kcMulti = {
  [KEYCLOAK_LOCATION.GSE]: kcGSE,
  [KEYCLOAK_LOCATION['recommendation-portal']]: kcRecomm,
  [KEYCLOAK_LOCATION['IOTAdminConsole']]: kcContent,
  [KEYCLOAK_LOCATION.TIPS]: kcGSE,
  [KEYCLOAK_LOCATION.ROUTINE]: kcRecomm,
};

export var kcRoles = {
  [KEYCLOAK_LOCATION.GSE]: process.env.REACT_APP_IS_CHINA
    ? GSE_ROLE_TYPE_CN
    : GSE_ROLE_TYPE,
  [KEYCLOAK_LOCATION['recommendation-portal']]: ROLE_TYPE,
  [KEYCLOAK_LOCATION['IOTAdminConsole']]: MALL_ROLE_TYPE,
  [KEYCLOAK_LOCATION['IOTAdminConsoleVda']]: VDA_ROLE_TYPE,
  [KEYCLOAK_LOCATION.TIPS]: process.env.REACT_APP_IS_CHINA
    ? TIPS_ROLE_TYPE_CN
    : TIPS_ROLE_TYPE,
  [KEYCLOAK_LOCATION['IOTAdminConsoleAssets']]: ASSETS_ROLE_TYPE,
  [KEYCLOAK_LOCATION.ROUTINE]: ROUTINE_ROLE_TYPE,
  [KEYCLOAK_LOCATION['IOTAdminConsoleExplore']]: EXPLORE_ROLE_TYPE,
};
