export const ERR_JSONFILE_NOT_FOUND = 'JSON File not found';
export const ERR_UNABLE_TO_PARSE_JSON = 'Unable to parse JSON string';
export const ERR_IMAGE_FILE_NOT_FOUND = 'Image file not found';
export const ERR_DARK_IMAGE_FILE_NOT_FOUND = 'Dark mode image file not found';
export const ERR_WIDE_IMAGE_FILE_NOT_FOUND = 'Wide image file not found';
export const ERR_WIDE_DARK_IMAGE_FILE_NOT_FOUND =
  'Wide dark mode image file not found';
export const ERR_IMAGE_EMPTY = 'Image cannot be empty';
export const ERR_TEMPLATE_NOT_FOUND = '"template" field not found';
export const ERR_IMAGE_UPLOAD_FAILED = 'Failed to upload image to bucket';
export const ERR_DARK_IMAGE_UPLOAD_FAILED =
  'Failed to upload dark mode image to bucket';
export const ERR_WIDE_IMAGE_UPLOAD_FAILED =
  'Failed to upload wide image to bucket';
export const ERR_WIDE_DARK_IMAGE_UPLOAD_FAILED =
  'Failed to upload wide dark mode image to bucket';
export const ERR_IMAGE_DOWNLOAD_FAILED = 'Failed to download image from bucket';
