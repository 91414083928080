export const REGION_LIST = [
  { value: 'ap', label: 'Asia Pacific' },
  { value: 'na', label: 'North America' },
  { value: 'eu', label: 'Europe' },
];

export const REGION_NAME = (() =>
  REGION_LIST.reduce((acc, region) => {
    acc[region.value] = region.label;
    return acc;
  }, {}))();
