import { STATUS_TYPE } from './common';
import moment from 'moment';

export const dummyCampaign = {
  draft: {
    campaignID: 'ST_VISION_RECOMMENDATION',
    state: STATUS_TYPE.DRAFT,
    name: 'ST Vision Recommendation',
    description: 'Promote users to use ST Vision',
    startTime: moment('2019-12-01T00:00:00').unix(),
    endTime: moment('2020-01-01T13:30:00').unix(),
    location: 'Korea',
    priority: 3,
    filter: 'All users who have bought a IoT Camera device(s)',
  },
};
