import axiosWrapper from '@/clients/gse/axiosWrapper';

export function generateUploadUrl(fileArray: any) {
  return new Promise((resolve, reject) => {
    return axiosWrapper
      .post('/utils/s3/signedurl', {
        data: fileArray,
      })
      .then(signedUrls => {
        resolve(signedUrls.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function uploadWithGeneratedUrl(
  generatedUrl: any,
  fileReaderResult: any,
  fileType: any,
) {
  return new Promise((resolve, reject) => {
    return axiosWrapper
      .put(`${generatedUrl}`, {
        headers: {
          'Content-Type': fileType,
        },
        data: fileReaderResult,
      })
      .then(uploadResponse => {
        return resolve(uploadResponse.status);
      })
      .catch(err => {
        return reject(err);
      });
  });
}

export function getImageDimensions(source: any) {
  return new Promise((resolve: any) => {
    const _URL = window.URL;
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    if (typeof source === 'object') {
      img.src = _URL.createObjectURL(source);
    } else {
      img.src = source;
    }
  });
}

export function isInCloud(fileKey: any) {
  return new Promise(resolve => {
    return axiosWrapper
      .get(`/utils/s3/exists/${encodeURIComponent(fileKey)}`)
      .then(() => {
        resolve(true);
      })
      .catch((err: any) => {
        console.log(err);

        resolve(false);
      });
  });
}
