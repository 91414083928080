import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from 'antd';
import { ErrorPage } from 'swuif';
import { realmLogout } from '@/routes/AuthLogin';

export const Page401 = (props: RouteComponentProps<any>) => {
  const { history, location } = props;
  const onBack = useCallback(() => history.replace('/'), [history]);
  const onLogout = () => {
    // keycloak.instance.logout({ redirectUri: window.location.origin });
    realmLogout();
  };
  let state: any = location.state || '';
  const msg = state.message || 'Your request is not authorized';
  return (
    <ErrorPage className="sw-page-401" code="401">
      <div className="sw-page-error-1">Sorry</div>
      <div className="sw-page-error-2">{msg}</div>
      <Button className="sw-page-error-btn" type="primary" onClick={onBack}>
        Back to Home
      </Button>
      <Button className="sw-page-error-btn" type="dashed" onClick={onLogout}>
        Use Different Account
      </Button>
    </ErrorPage>
  );
};
