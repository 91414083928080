import '@/style/index.less';
import React, { Suspense } from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
// import {
//   // ProtectedRoute,
//   SwuifApp,
// } from 'swuif';
import { SwuifApp, Page403, Page500 } from 'swuif';
import {
  swuifPreset,
  UNAUTHORIZED_URL,
  NOACCESS_URL,
  INTERNALSERVER_URL,
} from '@/config';
// import Auth from '@/routes/Auth';
// import AuthLogin from '@/routes/AuthLogin';
import Main from '@/routes/Main';
import Login from '@/routes/Main/Login';
import UserRegistration from '@/routes/Main/UserRegistration';
import '@/style/index.less';
import { Page401 } from './Page401';
import ActivityMonitoring from './ActivityMonitoring';

const frontPage = {
  main: <Route strict path="/" component={Main} />,
  login: <Route exact path="/" component={Login} />,
};

const App = (props: { registered: string }) => {
  return (
    <SwuifApp {...swuifPreset}>
      <BrowserRouter>
        <Suspense
          fallback={
            <div style={{ fontFamily: 'Samsung Sharp Sans Medium' }}>
              Loading...
            </div>
          }
        >
          <ActivityMonitoring>
            <Switch>
              {/* <Route strict path="/auth" component={Auth} /> */}
              {/* <Route strict path="/authLogin" component={AuthLogin} /> */}
              <Route strict path={UNAUTHORIZED_URL} component={Page401} />
              <Route strict path={NOACCESS_URL} component={Page403} />
              <Route strict path={INTERNALSERVER_URL} component={Page500} />
              {/* {props.registered === 'main' ? ( */}
              {/* <Route exact path="/register" component={Page401} /> */}
              {/* ) : ( */}
              <Route exact path="/register" component={UserRegistration} />
              {/* )} */}
              {frontPage[props.registered]}
              <Redirect to="/" />
              {/* <Route component={Page404} /> */}
            </Switch>
          </ActivityMonitoring>
        </Suspense>
      </BrowserRouter>
    </SwuifApp>
  );
};

export default hot(App);
